import React from 'react';
import { Modal, Button } from 'antd';



const { confirm } = Modal;

export default function DeleteButtonRenderer(props) {
console.log("component parent", props);
    const code = props.data.boxEanShort;
    const boxId = props.data.boxId;
    const state = props.data.state;

    const buttonClicked = (boxId, code) => {
        confirm({
            title: `Delete`,
            content: `Do you want to delete box ${code}?`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Close',
            onOk() {
                props.context.componentParent.handleDeleteBox(boxId)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };


    return (

            <span>
                {code != null && state === "in progress" ? (
                    <Button type="primary" className="stockIn-delete-button" onClick={() => buttonClicked(boxId, code)}>X</Button>
                ) : (
                    <div />
                )}
                
            </span>
  
    )

};



