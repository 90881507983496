const options = {
    columnDefs: [
            {
                headerName: "Id",
                field: "id",
                width: 50,
                filter: "agTextColumnFilter",   
            },
            {
                headerName: "Project name",
                field: "projectName",
                width: 110,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Address",
                field: "address",
                width: 180,
                filter: "agTextColumnFilter",
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};


export default options;