import * as React from 'react';
import {Icon} from '@bit/hlouzek.rhplus.icons';
import ClassNames from 'classnames';

export default function IconTale(props) {
  const {className, url, name, children, title, subtitle} = props;
  return (
    <React.Fragment>
      <a href={url} className="icon-description">
        <div {...props}>
          {children}
        </div>
        <div className="mt-1 mb-1">
          <p style={{margin: '2px', fontSize: '12px'}}>{title}</p>
          <p style={{fontSize: '10px'}}>{subtitle}</p>
        </div>
      </a>
    </React.Fragment>
  )
}