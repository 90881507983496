import { selector } from 'recoil';
import { FetchDocuments } from './data';
import { stockInDocumentDetailState } from './atoms';


export const StockInDocumentDetailSelector = selector({
    key: "stockInDocumentDetailSelector",
    get: async ({ get }) => {
        const documentNumber = get(stockInDocumentDetailState);
        const response = (await FetchDocuments(documentNumber))
            .data;
        console.log("refresh");
        return response;
        
    },
    dangerouslyAllowMutability: true
})

