import * as React from "react";
import BarcodeInput from "@bit/hlouzek.rhplus.barcode-input";
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { Modal } from 'antd';
import BoxDetail from './components/BoxDetail';
import LoadingOverlay from 'components/LoadingOverlay';

const { confirm } = Modal;

export default function LaptopDeficitExcessDetail() {
    const { setPageTitle } = usePage();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { boxInfo } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    const [data, setData] = React.useState([]);
    const [visibility, setVisibility] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);


    React.useEffect(() => {
        setPageTitle("Wms - Box Inventory")
    }, []);

    const onComplete = (e) => {
        console.log("success", e);
        loadData(e);
    };

    const onError = (e) => {
        console.log("error", e);
    };


    const loadData = async ean => {
        setIsActive(true);
        try {
            const response = await callApi({ ...boxInfo, paramObject: { ean: ean } })
            const result = response.data;
            console.log("result", response);
            setData(result)
            setIsActive(false);
            setVisibility(true);

        }
        catch (err) {
            loadingError(ean);
            setIsActive(false);
        }
    }

    const loadingError = ean => {
        Modal.error({
            title: 'Error',
            content: `Cannot find ean ${ean}`,
        });
    }



    return (
            <LoadingOverlay
                active={isActive}
            >
                <div className="box-inventory">
                    <h3>Scan barcode</h3>
                    <BarcodeInput
                        onSearch={onComplete}
                        onError={onError}
                        searchProperties={{
                            style: {
                                width: 450
                            }
                        }}
                        showSearchButton={true}
                        showBarcodeButton={true}
                        hideCameraAfterDetection={true}
                        cameraPosition="down"
                        cameraClassName="mt-3"
                        className="barcode-input"
                        barcodeIconStyle="barcode-icon"
                    />
                    <BoxDetail visibility={visibility} data={data} style={{ marginTop: '50px', textAlign: 'left' }} />
                </div>
            </LoadingOverlay>
    )
}