import moment from 'moment';

const options = {
    columnDefs: [
            {
                headerName: "Position",
                field: "position.label",
                filter: "agTextColumnFilter", 
                width: 150,
            },
            {
                headerName: "Ean",
                field: "position.ean",
                filter: "agTextColumnFilter", 
                width: 150,
            },
            {
                headerName: "Floor",
                field: "position.floor",
                filter: "agTextColumnFilter", 
                width: 100,
            },
            { 
                headerName: 'Line', 
                field: 'position.line', 
                filter: "agTextColumnFilter",
                width: 100, 
            },
            {
                headerName: "Shelf",
                field: "position.shelf",
                filter: "agTextColumnFilter", 
                width: 100,
            },
            {
                headerName: "Created",
                field: "createdDate",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm:ss"),
                width: 200
            },
            {
                headerName: "Inventory",
                field: "inventoryDate",
                filter: "agTextColumnFilter",
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm:ss"),
                width: 200,
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.inventoryDate != null },
    },
};





export default options;