import * as React from 'react';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import UseChartBoxMovement from './useChartBoxMovement';
import { Button } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { Modal, Collapse } from 'antd';
import { Label } from 'devextreme-react/chart';


const { Panel } = Collapse;

export default function BoxContent({ data, chartData, visible }) {
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { infoPrintBox } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    console.log("Box content info", data);



    const onPrintClick = data => {
        Modal.confirm({
            title: 'Are you sure you want to print the box?',
            onOk: printBox(data)
        })
    }
    const printBox = async data => {
        const id = data.box.id;
        setLoading(true);
        try {
            const response = await callApi({ ...infoPrintBox, paramObject: { boxId: id } })
            setLoading(false);
        }
        catch (err) {
            printBoxError();
            setLoading(false);
        }
    }

    const printBoxError = () => {
        Modal.error({
            title: 'Error',
            content: "Connection problem, please try again later.",
        });
    }

    const { position: { position: { label } = {} } = {}, box: { ean, quantity, product: { code, description } = {} } = {} } = data;
    if (visible === true) {
        return (
            <CardBox styleName="mb-3" heading="Box content">
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                        <div>
                            <h3>{code}</h3>
                            <p>{description}</p>
                        </div>
                        <Button type="primary" onClick={() => onPrintClick(data)}>Print box</Button>
                    </div>
                    <Collapse defaultActiveKey={['1']} ghost>
                        <Panel header="Show graph" key="1">
                            <UseChartBoxMovement data={chartData} />
                            <div style={{ width: '80%', margin: 'auto', marginTop: '30px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                                    <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                        <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Ean:</span>
                                        <span>{ean}</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                        <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                        <span>{quantity}</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                        <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                        <span>{label}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </CardBox>
        )
    } else {
        return (
            <div />
        )
    }


}