import * as React from 'react';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import NavBar from 'components/NavBar';
import { Switch, Affix } from 'antd';
import { useRecoilValue, useRecoilState } from "recoil";
import { sortedPositionsInventoryQueueState } from "./recoil/selectors";
import { positionsInventoryQueueSortingType } from './recoil/atoms';
import { positionsInventoryQueueSortingTypeEnum } from './recoil/constants';



export default function MobilePositions() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const data = useRecoilValue(sortedPositionsInventoryQueueState);
    const [positionsInventoryQueueSorting, setPositionsInventoryQueueSorting] = useRecoilState(positionsInventoryQueueSortingType);
    const [top, setTop] = React.useState(100);


    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Positions")
    }, []);

    const handleSortingChange = e => {
        if (!!e)
        setPositionsInventoryQueueSorting(positionsInventoryQueueSortingTypeEnum.BY_INVENTORY);
        else
        setPositionsInventoryQueueSorting(positionsInventoryQueueSortingTypeEnum.BY_LABEL);
    }


    return (
        <div className="mobile-positions">
            <Affix offsetTop={top}>
            <h5 style={{ textAlign: 'center' }}>Positions to inventory</h5>
            <Switch
                    checkedChildren="In progress"
                    unCheckedChildren="All"
                    onChange={handleSortingChange}
                    style={{ width: '100px', margin: 'auto', marginTop: '15px' }}
                />
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                <div ref={wrapper}
                    className="ag-theme-material"
                    style={{ width: '90%', justifyContent: 'center', margin: 'auto', marginTop: '10px', marginLeft: '20px', marginRight: '20px' }}
                >
                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data}
                        defaultColDef={options.defaultColDef}
                        // floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        rowClassRules={options.rowClassRules}
                        gridOptions={options.gridOptions}

                    >
                    </AgGrid>
                </div>
            {/* </Loader> */}
            </Affix>
            <NavBar />
        </div>
    )
}