const options = {
    columnDefs: [
        {
            headerName: "State",
            field: "state",
            width: 65,
        }, 
        {
            headerName: "Document no.",
            field: "documentNumber",
            width: 72,
            cellRenderer: params => {
                const { documentNumber } = params.data;
                const id = documentNumber
                if (!!id) {
                  return `<a href='stock-out/${documentNumber}'>${documentNumber}</a>`
                }
              }
        },
        {
            headerName: "Company",
            field: "company",
            width: 80,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: "Note",
            field: "note",
            width: 125,
            autoHeight: true,
            wrapText: true,
        }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.state === "OK"; },
    },
    rowHeight: 150,
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};





export default options;