import { selector } from 'recoil';
import { FetchProductSpecificationInventory } from './data';
import Enumerable from 'linq';
import { productSpecificationInventorySortingType } from './atoms';
import { productSpecificationInventorySortingTypeEnum } from './constants';

export const productSpecificationInventoryState = selector({
    key: "productSpecificationInventoryState",
    get: async () => {
        const response = (await FetchProductSpecificationInventory())
            .data.items;
        return response;
    },
    dangerouslyAllowMutability: true
})

const productCompleteInfo = item => {
    console.log("item", item);
    const {
        product: {
            packageDimension,
            packageWeight,
            emptyPackageWeight,
            sizeType,
            ralCode,
            color,
            material,
            packageQuantity,
            weight } = {},
        position: { label } = {}
    } = item;
    if (packageDimension && packageWeight && emptyPackageWeight && sizeType && ralCode && color && material && packageQuantity && weight && label)
        return 2;
    else
        return 1;
}


export const sortedProductSpecificationInventoryState = selector({
    key: "sortedProductSpecificationInventoryState",
    get: ({ get }) => {
        const items = get(productSpecificationInventoryState);
        const sortingType = get(productSpecificationInventorySortingType);

        switch (sortingType) {
            case productSpecificationInventorySortingTypeEnum.BY_LABEL:
                return Enumerable.from(items)
                    .orderBy(item => item.label)
                    .toArray();

            case productSpecificationInventorySortingTypeEnum.BY_COMPLETE:
                return Enumerable.from(items)
                    .orderBy(item => productCompleteInfo(item))
                    .thenByDescending(item => item.label)
                    .toArray();
        }
        return [];
    },
    dangerouslyAllowMutability: true
})
