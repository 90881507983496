import moment from 'moment';

const options = {
    columnDefs: [
        {
            headerName: "Document number",
            field: "documentNumber",
            width: 60,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: "Company",
            field: "company",
            width: 70,
        },
        {
            headerName: "Qty",
            field: "quantity",
            width: 40,
        },
        {
            headerName: "Created date",
            field: "createdDate",
            width: 50,
            autoHeight: true,
            wrapText: true,
            cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY HH:mm"),
        },
        {
            headerName: "Note",
            field: "note",
            width: 75,
        },
        {
            headerName: "Flow qty",
            field: "flowQuantity",
            width: 60,
        }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.quantity > 0 },
        'rag-red-outer': function(params) {return params.data.quantity < 0}
    },
    rowHeight: 150,
};





export default options;
