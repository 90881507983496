const options = {
    columnDefs: [
            {
                headerName: "Label",
                field: "position.label",
                filter: "agTextColumnFilter", 
                width: 70,
            },
            {
                headerName: "Code",
                field: "product.code",
                filter: "agTextColumnFilter", 
                width: 100,
            },
            {
                headerName: "Description",
                field: "product.description",
                filter: "agTextColumnFilter", 
                width: 280,
            },
            { 
                headerName: 'Carton Dimension', 
                field: 'product.packageDimension', 
                filter: "agTextColumnFilter",
                width: 120, 
            },
            {
                headerName: "Product Weight",
                field: "product.weight",
                filter: "agTextColumnFilter", 
                width: 100,
            },
            {
                headerName: "Quantity per Carton",
                field: "product.packageQuantity",
                filter: "agTextColumnFilter", 
                width: 100
            },
            {
                headerName: "Material",
                field: "product.material",
                filter: "agTextColumnFilter",
                width: 100,
            },
            {
                headerName: "Color",
                field: "product.color",
                filter: "agTextColumnFilter",
                width: 100
            },
            {
                headerName: "Ral Code",
                field: "product.ralCode",
                filter: "agTextColumnFilter",
                width: 100
            },
            {
                headerName: "Size Type",
                field: "product.sizeType",
                filter: "agTextColumnFilter",
                width: 100
            },
            {
                headerName: "Full package weight",
                field: "product.packageWeight",
                filter: "agTextColumnFilter",
                width: 100
            },
            {
                headerName: "Empty package weight",
                field: "product.emptyPackageWeight",
                filter: "agTextColumnFilter",
                width: 120
            },
            {
                headerName: "Photos",
                field: "photos",
                filter: "agTextColumnFilter",
                width: 100
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    gridOptions: {
        getRowClass: function(params) {
          const {
            packageDimension,
            packageWeight,
            emptyPackageWeight,
            sizeType,
            ralCode,
            color,
            material,
            packageQuantity,
            weight 
          } = params.data.product;
          
          if (packageDimension && packageWeight && emptyPackageWeight && sizeType && ralCode && color && material && packageQuantity && weight) {
            return 'table-success';
          }
          else if (packageDimension) {
            return 'table-warning';
          }
          else if ((weight || packageWeight)) {
            return 'table-info';
          }
        }
      }
};






export default options;