import * as React from 'react';
import { Modal } from 'antd';

export default function DocumentDetailPickBoxModal({ show, onHide, data = [], handleScanner }) {
    console.log("doc det pick mod data", data);

    if (show === true) {
        return (
            <Modal
                title="Scan new position"
                visible={show}
                onCancel={onHide}
                cancelText="Close"
            > 
                <h6>{data.product}</h6>
                <p>{data.description}</p>
                <table>
                    <tbody>
                        <tr>
                            <td>Quantity</td>
                            <th>{data.boxQuantity}</th>
                        </tr>
                        <tr>
                            <td>Position</td>
                            <th>{data.position}</th>
                        </tr>
                    </tbody>
                </table>
            </Modal>
        )
    }
    else {
        return (
            <div />
        )
    }
}



  