import moment from 'moment';


const options = {
    columnDefs: [
            {
                headerName: "Delivery",
                field: "delivery",
                width: 285,
                filter: "agTextColumnFilter", 
                cellClass: "grid-cell-centered"  
            },
            {
                headerName: "Pallet",
                field: "pallet",
                width: 285,
                filter: "agTextColumnFilter",
                cellClass: "grid-cell-centered"
            },
            {
                headerName: "Closed",
                field: "closed",
                cellRenderer: params => !!params.value ? moment(params.value).format("DD.MM.YYYY HH:mm") : "",
                width: 285,
                filter: "agTextColumnFilter",
                cellClass: "grid-cell-centered"
            },
            {
                headerName: "Actions",
                field: "actions",
                width: 200,
                filter: "agTextColumnFilter",       
                cellRenderer:"deleteButtonRenderer",
                cellClass: "grid-cell-centered"
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
};



export default options;
