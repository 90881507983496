const options = {
    columnDefs: [
            {
                headerName: "Qty",
                field: "ordered",
                width: 100,
                filter: "agTextColumnFilter",
                cellRenderer: 'stockoutQuantityRenderer', 
              
            },
            {
                headerName: "Product",
                field: "product",
                width: 100,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Description",
                field: "description",
                width: 300,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Position",
                field: "positions",
                width: 150,
                filter: "agTextColumnFilter",
                cellClassRules: {
                    'green-stock-outer': function(params) { return params.data.inStock > params.data.ordered && params.data.stockOut === 0 || params.data.inStock === params.data.ordered},
                    'yellow-stock-outer': function(params) {return params.data.ordered > params.data.inStock && params.data.inStock != 0 && params.data.stockOut === 0},
                    'red-stock-outer': function(params) {return params.data.inStock === 0 && params.data.stockOut === 0}
                },
                cellRenderer: params => {
                    const { palette, positions } = params.data;
                    if(!!palette) {
                        return `<a href='https://stockout.rhplus.cloud/app/stockout/detail/${palette}'>${palette}</a>`
                    } else {
                        return `<span>${positions}</span>`
                    }
                      
                }
            },
            {
                headerName: "State",
                field: "state",
                width: 130,
                cellRenderer: params => {
                    const {ordered, stockOut} = params.data;
                    if(ordered-stockOut === 0) {
                        return `<span>OK</span>`
                    } else {
                        return `<span>In progress</span>`
                    }
                },
                cellClassRules: {
                    'green-outer': function(params) { return params.data.ordered - params.data.stockOut === 0; },
                },
                cellClass: "left-margin"
                
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
};




export default options;