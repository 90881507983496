import * as React from 'react';
import ReactLoadingOverlay from 'react-loading-overlay';

export default function LoadingOverlay(props) {
  const {children, isActive = false} = props;

  return (
    <ReactLoadingOverlay
      styles={{
        wrapper: {
          width: "100wv",
          height: "100hv",
          overflow: "hidden",
        },
      }}
      active={isActive}
      spinner
      text="Loading..."
    >
      {children}
    </ReactLoadingOverlay>
  )
}