import * as React from 'react';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import DeleteButtonRenderer from './components/DeleteButtonRenderer';
import { Modal, Button, Affix } from 'antd';
import NavBar from 'components/NavBar';

export default function MobileDeliveryDetail({ search, data }) {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const [top, setTop] = React.useState(130);
    const [state, setState] = React.useState({
        title: "Pick delivery barcode",
        assignedDelivery: {},
        isLoading: true
    })




    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Delivery Detail")
    }, []);

    const getRowStyle = params => {
        if (params.data.closed != "") {
            return { background: '#c3e6cb' };
        }
    };


    return (
        <div className="mobile-delivery">
            <Affix offsetTop={top}>
            <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px', minHeight: '50px' }}>
                    <h4>{data.project}</h4>
                    <Button type="primary">Create delivery</Button>
                </div>
                <div style={{minHeight: '10px', marginBottom: '15px'}}><p>{data.address}</p></div>
                <h3>{state.title}</h3>
            </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                <div ref={wrapper}
                    className="ag-theme-material"
                    style={{ width: '90%', justifyContent: 'center', margin: 'auto', marginTop: '30px', marginLeft: '20px', marginRight: '20px' }}
                >

                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data.pallets}
                        defaultColDef={options.defaultColDef}
                        // floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        getRowStyle={getRowStyle}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        frameworkComponents={{
                            deleteButtonRenderer: DeleteButtonRenderer,
                        }}
                        rowHeight={50}
                        gridOptions={options.gridOptions}
                    >
                    </AgGrid>
                </div>
            {/* </Loader> */}
            </Affix>
            <NavBar />
        </div>
    )


}