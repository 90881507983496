import * as React from 'react';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';

export const FetchDocuments = async documentNumber => {
  const { stockOutDocumentDetail } = apis;
  const { callApi } =  useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);  
  const response = await callApi({ ...stockOutDocumentDetail(documentNumber)});
  return response;
}

