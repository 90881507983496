import * as React from 'react';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { useRecoilValue, useRecoilState } from "recoil";
import { pickPositionState, pickBoxState } from './recoil/atoms';
import { StockInDocumentDetailSelector } from "./recoil/selectors";
import {useRecoilRefresher_UNSTABLE} from "recoil";
import { Button, Modal, message } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import LoadingOverlay from 'components/LoadingOverlay';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import DocumentDetailPickBoxModal from './components/DocumentDetailPickBoxModal';
import CreateBoxModal from './components/CreateBoxModal';
import DeleteButtonRenderer from './components/DeleteButtonRenderer';
import RowSpanRenderer from './components/RowSpanRenderer';
import Enumerable from 'linq';




export default function LaptopDetail() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const gridData = useRecoilValue(StockInDocumentDetailSelector);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { printAll, stockInBoxInfo, getInfo, stockInToBox, getDocumentItemById, stockInToPosition, printBox, infoBeforeCreateBox, deleteBox } = apis;
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [boxModalShow, setBoxModalShow] = React.useState(false);
    const [createBoxModalShow, setCreateBoxModalShow] = React.useState(false);
    const [createBoxData, setCreateBoxData] = React.useState({});
    const [box, setBox] = React.useState({});
    const { success, error } = useBeeper();
    const [pickPosition, setPickPosition] = useRecoilState(pickPositionState);
    const [pickBox, setPickBox] = useRecoilState(pickBoxState);
    const RecoilRefresh = useRecoilRefresher_UNSTABLE(StockInDocumentDetailSelector);



 
    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle(`Wms - Stock In ${gridData.documentCode}`)
    }, [, gridData]);


    
    const onPrintButtonClick = () => {
        setIsModalVisible(true);
    }

    const printErrorModal = () => {
        Modal.error({
            title: 'Error',
            content: 'Cannot print ticket!',
        });
    }

    const errorMessage = message => {
        Modal.error({content: message});
    }
    

    const handlePrintBox = async (e) => {
        setIsActive(true);
        handleCreateBoxModalHide();
        const {
            id,
            boxId,
            productId,
            quantity,
            deficit,
            surplus,
            demaged,
            delivered,
            refresh,
        } = e;
        const input = {
            DocumentItemId: id,
            BoxId: boxId || null,
            ClientProductId: productId,
            Quantity: quantity,
            Deficit: deficit,
            Surplus: surplus,
            Demaged: demaged,
        };

        try {
            const response = await callApi({ ...printBox, paramObject: { ...input } })
            const { boxId } = response.data;
            await success();

            if (refresh) {
                RecoilRefresh();
            } else {

                setPickBox({
                    id: id,
                    boxId: parseInt(boxId),
                    initQuantity: delivered,
                    boxed: delivered,
                    boxEanShort: `*${boxId}`,
                })
            }
        }
        catch (err) {
            await error();
            printErrorModal();
        }
    };


    const handleCreateBoxModalHide = (reload) => {
        setCreateBoxModalShow(false)
        if (reload) {
          RecoilRefresh();
        }
      };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onComplete = (e) => {
        if (!boxModalShow) handlePickBox(e);
        else handlePickPosition(e);
    };

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const errorModal = () => {
        Modal.error({
            title: "The target box does not match the selected product",
        });
    }

    const warningModal = (positionLabel, handleBoxEan, documnetItemId) => {
        Modal.confirm({
            title: `Do you want to regroup goods to the target box at position ${positionLabel}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: handleStockInToBox(handleBoxEan, documnetItemId, positionLabel),
        });
    }

    const handleStockInToBox = async (handleBoxEan, documnetItemId, positionLabel) => {
        setIsActive(true);
        try {
            const response = await callApi({
                ...stockInToBox, paramObject: {
                    documentItemId: box.id,
                    sourceBoxEan: box.boxEAN,
                    destinationBoxEan: handleBoxEan,
                    quantity: box.boxQuantity
                }
            })
            if (response.status === 200) {
                await success();
                handleGetDocumentItemById(documnetItemId, positionLabel);
                setIsActive(false);

            } else {
                await error();
                setIsActive(false);
            }
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }


    }

    const handleGetDocumentItemById = async (documentItemId, positionLabel) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...getDocumentItemById, paramObject: { documentItemId } })
            const { stockOut, state, remainStockOut } = response.data.item;
            setPickPosition({
                key: `${documentItemId}_${box.boxId}`,
                boxId: box.boxId,
                stockOut,
                remainStockOut,
                position: positionLabel.id,
                state,
                positionName: positionLabel.name,
                documentItemId
            });
            setBoxModalShow(false);
            setIsActive(false);
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }

    const handleStockInToPosition = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({
                ...stockInToPosition, paramObject: {
                    DocumentItemId: box.id,
                    BoxId: box.boxId,
                    PositionEAN: e,
                    Quantity: box.boxQuantity,
                    BoxEAN: box.boxEAN,
                }
            })
            await success();
            setBoxModalShow(false);
            setPickPosition({
                key: response.data.key,
                boxId: response.data.boxId,
                stockOut: response.data.stockOut,
                remainStockOut: response.data.remainStockOut,
                position: response.data.positionId,
                state: response.data.state,
                positionName: response.data.positionName,
                documentItemId: response.data.documentItemId,
            });
            setIsActive(false);
            RecoilRefresh();
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }

    const handlePickBox = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...stockInBoxInfo, paramObject: { Ean: e, Document: gridData.documentCode } })
            setBoxModalShow(true);
            setBox(response.data);
            setIsActive(false);
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false)
        }
    };

 
    const handlePickPosition = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...getInfo, paramObject: { ean: e } })
            const {
                box: handleBox,
                position: { position },
            } = response.data;

            if (handleBox != null) {
                const { id: documnetItemId, clientProductId } = box;

                if (clientProductId !== handleBox.product.productId) {
                    await error();
                    errorModal();
                    setBoxModalShow(false);
                    return;
                }

                if (clientProductId === handleBox.product.productId) {
                    warningModal(handleBox.position.label, handleBox.ean, documnetItemId);
                }
            }
            else if (handleBox === null && position !== null) {
                handleStockInToPosition(e);
            }


        } catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    };

    const loadPrintData = async input => {
        setIsActive(true);
        try {
            const response = await callApi({ ...printAll, paramObject: input })
            if (response.status === 200) {
                printSuccess();
                setIsActive(false);

            } else {
                printError();
                setIsActive(false)
            }
        }
        catch (err) {
            errorMessage(err);
            setIsActive(false);
        }

    }

    const splitSubmit = (id, quantity) => {
        console.log("split item", id, quantity);
    };

    const printSuccess = () => {
        Modal.success({
            content: 'Successfully sent to print.',
        });
    }


    const printError = () => {
        Modal.error({
            title: 'Transmission failed',
            content: 'Connection problem, please try again later.',
        });
    }

    const handlePrintAll = () => {
        const input = {
            "documentCode": gridData.documentCode,
        }
        loadPrintData(input);
        setIsModalVisible(false);
    };

    const onCellClicked = async e => {
        const colId = e.column.colDef.field;
      
        if (colId === "product") {
            loadInfoBeforeCreateBox(e);
        }

    }

    const loadInfoBeforeCreateBox = async (e) => {
        setIsActive(true);
        const value = e.data.id;
        const boxId = e.data.boxId;
        try {
            const response = await callApi({ ...infoBeforeCreateBox, paramObject: {documentItemId: value}})
            const result = response.data
            console.log("boxData", result.documentItem);
            if (
                result.boxes === null ||
                result.boxes.filter((f) => f.id === boxId).length === 0
              ) {
                  setCreateBoxModalShow(true);
                  setCreateBoxData({...result, initialQuantity: e.data.initialQuantity});
              } else {
                handleCheckPrintBox(e);
              }
            setIsActive(false);
            
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }

    const handleCheckPrintBox = (e) => {
        Modal.confirm({
            title: "Print box ean?",
            content: "if not exists, it'll create",
            okText: "Print",
            cancelText: "Close",
            onOk: handlePrintBox(e),
        });
      };

    const handleBoxModalHide = () => {
        setBoxModalShow(false);
    };

    
    const handleDeleteBox = async ( boxId ) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...deleteBox, paramObject: { boxId: boxId } })
            if (response.status === 200) {
                success()
                deleteSuccess();
                setIsActive(false);
                RecoilRefresh();

            } else {
                error()
                deleteError();
                setIsActive(false);
            }
        }
        catch (err) {
            error()
            errorMessage(err);
            setIsActive(false);
        }
    }

    const deleteSuccess = () => {
        Modal.success({content: 'Successfully deleted'});
    }


    const deleteError = () => {
        Modal.error({content: 'Error! Box cannot be delete!'});
    }

    UseScannerDetection({ onError, onComplete });

    const { documentItems: { documentItems } = {} } = gridData;
    const dataWithoutStorno = Enumerable.from(documentItems)
        .where(w => w.state != 'Storno')
        .toArray()
    return (
        <LoadingOverlay active={isActive} >
            <div className="stock-in-detail">
                <div>
                    {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                    <div style={{ width: '58%', justifyContent: "center", margin: 'auto' }}>
                        <Button type="primary" onClick={onPrintButtonClick}>Print all</Button >
                        <Modal
                            title="Print all boxes?"
                            visible={isModalVisible}
                            onOk={handlePrintAll}
                            onCancel={handleCancel}
                            okText="Print all"
                            cancelText="Close"
                            okType="primary">
                            <p>Do you really want to print all boxes?</p>
                        </Modal>
                    </div>
                    <CreateBoxModal
                        splitSubmit={splitSubmit}
                        submit={handlePrintBox}
                        show={createBoxModalShow}
                        data={createBoxData}
                        items={documentItems}
                        close={handleCreateBoxModalHide}
                    />
                    <DocumentDetailPickBoxModal
                        show={boxModalShow}
                        onHide={handleBoxModalHide}
                        data={box}
                        handleScanner={onComplete}
                    />
                    <div ref={wrapper}
                        className="ag-theme-balham"
                        style={{ width: '60%', margin: 'auto', marginTop: '10px' }}
                    >

                        <AgGrid
                            columnDefs={options.columnDefs}
                            rowData={dataWithoutStorno}
                            defaultColDef={options.defaultColDef}
                            floatingFilter={true}
                            suppressRowTransform={true}
                            autoGroupColumnDef={options.autoGroupColumnDef}
                            // getRowStyle={getRowStyle}
                            //   sideBar={options.sideBar}
                            onCellClicked={onCellClicked}
                            handleDeleteBox={handleDeleteBox}
                            context={{
                                componentParent: this
                            }}
                            
                            frameworkComponents={{
                                deleteButtonRenderer: DeleteButtonRenderer,
                                rowSpanRenderer: RowSpanRenderer
                            }}
                             getRowNodeId={data => data.id}
                             gridOptions={options.gridOptions}
                        >
                        </AgGrid>
                    </div>

                    {/* </Loader> */}

                </div>

            </div>
        </LoadingOverlay>
    )
}