import * as React from 'react';
import LaptopDeliveryDetail from './LaptopVersion';
import MobileDeliveryDetail from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import {favicons, useFavicon} from '@bit/hlouzek.rhplus.favicon';

function getTitleEl() {
    return document.getElementById("app-title");
  }

export default function StockIn({deliveryCode}) {
    const [data, setData] = React.useState([]);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { deliveryProjectPallets } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    console.log("first deliveryCOde", deliveryCode);

    useFavicon({url: favicons.wms.delivery});

    const titleChange = () => {
        const title = getTitleEl();
        console.log("title", title);
        // favicon.href = `${process.env.PUBLIC_URL} + /favicons/stockInIcon.ico`;
        title.innerHTML = "Delivery"
      };
    
      React.useEffect(() => {
        titleChange();
    }, []);



    React.useEffect(() => {
        loadData(deliveryCode);
    }, []);

    const loadData = async deliveryCode => {
        console.log("deliveryCode", deliveryCode);
        setLoading(true);
        try {
            const response = await callApi({ ...deliveryProjectPallets, paramObject: { projectId: deliveryCode } })
            const result = response.data;
            console.log("result", result);

            setData(result)
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    return (
        <div>
            <ShowAt breakpoint="xlarge" >
                <LaptopDeliveryDetail search={loadData} data={data} deliveryCode={deliveryCode} />
            </ShowAt>
            <ShowAt breakpoint="large">
                <LaptopDeliveryDetail search={loadData} data={data} deliveryCode={deliveryCode} />
            </ShowAt>
            <ShowAt breakpoint="medium">
                <MobileDeliveryDetail search={loadData} data={data} deliveryCode={deliveryCode} />
            </ShowAt>
            <ShowAt breakpoint="small">
                <MobileDeliveryDetail search={loadData} data={data} deliveryCode={deliveryCode} />
            </ShowAt>
        </div>
    )
}


