import * as React from 'react';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { useRecoilValue, useRecoilState } from "recoil";
import { pickPositionState, pickBoxState } from './recoil/atoms';
import { StockInDocumentDetailSelector } from "./recoil/selectors";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { Button, Affix, Modal, message, Input, Switch } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import LoadingOverlay from 'components/LoadingOverlay';
import NavBar from 'components/NavBar';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import DocumentDetailPickBoxModal from './components/DocumentDetailPickBoxModal';
import CreateBoxModal from './components/CreateBoxModal';
import DeleteButtonRenderer from './components/DeleteButtonRenderer';
import RowSpanRenderer from './components/RowSpanRenderer';
import Enumerable from 'linq';


export default function MobileDetail() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const gridData = useRecoilValue(StockInDocumentDetailSelector);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { printAll, stockInBoxInfo, getInfo, stockInToBox, getDocumentItemById, stockInToPosition, printBox, infoBeforeCreateBox, deleteBox } = apis;
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [boxModalShow, setBoxModalShow] = React.useState(false);
    const [createBoxModalShow, setCreateBoxModalShow] = React.useState(false);
    const [createBoxData, setCreateBoxData] = React.useState({});
    const [box, setBox] = React.useState({});
    const { success, error } = useBeeper();
    const [pickPosition, setPickPosition] = useRecoilState(pickPositionState);
    const [pickBox, setPickBox] = useRecoilState(pickBoxState);
    const RecoilRefresh = useRecoilRefresher_UNSTABLE(StockInDocumentDetailSelector);
    const [top, setTop] = React.useState(130);
    const [search, setSearch] = React.useState('');
    const [gridApi, setGridApi] = React.useState({});
    const [columnApi, setColumnApi] = React.useState({});
    const [filteredData, setFilteredData] = React.useState();




    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle(`Stock In ${gridData.documentCode}`)
    }, [, gridData]);

    React.useEffect(() => {
        setFilteredData(inProgress);
    }, [, gridData])

    const onPrintButtonClick = () => {
        setIsModalVisible(true);
    }

    const printErrorModal = () => {
        Modal.error({
            title: 'Error',
            content: 'Cannot print ticket!',
        });
    }

    const errorMessage = message => {
        Modal.error({ content: message });
    }

    const handlePrintBox = async (e) => {
        setIsActive(true);
        handleCreateBoxModalHide();
        const {
            id,
            boxId,
            productId,
            quantity,
            deficit,
            surplus,
            demaged,
            delivered,
            refresh,
        } = e;
        const input = {
            DocumentItemId: id,
            BoxId: boxId || null,
            ClientProductId: productId,
            Quantity: quantity,
            Deficit: deficit,
            Surplus: surplus,
            Demaged: demaged,
        };

        try {
            const response = await callApi({ ...printBox, paramObject: { ...input } })
            const { boxId } = response.data;
            await success();

            if (refresh) {
                RecoilRefresh();
            } else {

                setPickBox({
                    id: id,
                    boxId: parseInt(boxId),
                    initQuantity: delivered,
                    boxed: delivered,
                    boxEanShort: `*${boxId}`,
                })
            }
        }
        catch (err) {
            await error();
            printErrorModal();
        }
    };

    const handleCreateBoxModalHide = (reload) => {
        setCreateBoxModalShow(false)
        if (reload) {
            RecoilRefresh();
        }
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onComplete = (e) => {
        if (!boxModalShow) handlePickBox(e);
        else handlePickPosition(e);
    };

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const errorModal = () => {
        Modal.error({
            title: "The target box does not match the selected product",
        });
    }

    const warningModal = (positionLabel, handleBoxEan, documnetItemId) => {
        Modal.confirm({
            title: `Do you want to regroup goods to the target box at position ${positionLabel}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: handleStockInToBox(handleBoxEan, documnetItemId, positionLabel),
        });
    }

    const handleStockInToBox = async (handleBoxEan, documnetItemId, positionLabel) => {
        setIsActive(true);
        try {
            const response = await callApi({
                ...stockInToBox, paramObject: {
                    documentItemId: box.id,
                    sourceBoxEan: box.boxEAN,
                    destinationBoxEan: handleBoxEan,
                    quantity: box.boxQuantity
                }
            })
            if (response.status === 200) {
                await success();
                handleGetDocumentItemById(documnetItemId, positionLabel);
                RecoilRefresh();
                setIsActive(false);

            } else {
                await error();
                setIsActive(false);
            }
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }


    }

    const handleGetDocumentItemById = async (documentItemId, positionLabel) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...getDocumentItemById, paramObject: { documentItemId } })
            const { stockOut, state, remainStockOut } = response.data.item;
            setPickPosition({
                key: `${documentItemId}_${box.boxId}`,
                boxId: box.boxId,
                stockOut,
                remainStockOut,
                position: positionLabel.id,
                state,
                positionName: positionLabel.name,
                documentItemId
            });
            setBoxModalShow(false);
            setIsActive(false);
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }

    const handleStockInToPosition = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({
                ...stockInToPosition, paramObject: {
                    DocumentItemId: box.id,
                    BoxId: box.boxId,
                    PositionEAN: e,
                    Quantity: box.boxQuantity,
                    BoxEAN: box.boxEAN,
                }
            })
            await success();
            setBoxModalShow(false);
            setPickPosition({
                key: response.data.key,
                boxId: response.data.boxId,
                stockOut: response.data.stockOut,
                remainStockOut: response.data.remainStockOut,
                position: response.data.positionId,
                state: response.data.state,
                positionName: response.data.positionName,
                documentItemId: response.data.documentItemId,
            });
            setIsActive(false);
            RecoilRefresh();
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }

    const handlePickBox = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...stockInBoxInfo, paramObject: { Ean: e, Document: gridData.documentCode } })
            setBoxModalShow(true);
            setBox(response.data);
            setIsActive(false);
        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false)
        }
    };

    const handlePickPosition = async (e) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...getInfo, paramObject: { ean: e } })
            const handleBox = response.data.box;
            const position = response.data.position;

            if (handleBox != null) {
                const { id: documnetItemId, clientProductId } = box;

                if (clientProductId !== handleBox.product.productId) {
                    await error();
                    errorModal();
                    setBoxModalShow(false);
                    return;
                }

                if (clientProductId === handleBox.product.productId) {
                    warningModal(handleBox.position.label, handleBox.ean, documnetItemId);
                }
            }
            else if (handleBox === null && position !== null) {
                handleStockInToPosition(e);
            }


        } catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    };

    const loadPrintData = async input => {
        setIsActive(true);
        try {
            const response = await callApi({ ...printAll, paramObject: input })
            if (response.status === 200) {
                printSuccess();
                setIsActive(false);

            } else {
                printError();
                setIsActive(false)
            }
        }
        catch (err) {
            errorMessage(err);
            setIsActive(false);
        }

    }

    const splitSubmit = (id, quantity) => {
        console.log("split item", id, quantity);
    };

    const printSuccess = () => {
        Modal.success({
            content: 'Successfully sent to print.',
        });
    }

    const printError = () => {
        Modal.error({
            title: 'Transmission failed',
            content: 'Connection problem, please try again later.',
        });
    }

    const handlePrintAll = () => {
        const input = {
            "documentCode": gridData.documentCode,
        }
        loadPrintData(input);
        setIsModalVisible(false);
    };



    const onCellClicked = async e => {
        const colId = e.column.colDef.field;

        if (colId === "product") {
            loadInfoBeforeCreateBox(e);
        }

    }

    const loadInfoBeforeCreateBox = async (e) => {
        setIsActive(true);
        const value = e.data.id;
        const boxId = e.data.boxId;
        try {
            const response = await callApi({ ...infoBeforeCreateBox, paramObject: { documentItemId: value } })
            const result = response.data
            console.log("boxData", result.documentItem);
            if (
                result.boxes === null ||
                result.boxes.filter((f) => f.id === boxId).length === 0
            ) {
                setCreateBoxModalShow(true);
                setCreateBoxData({ ...result, initialQuantity: e.data.initialQuantity });
            } else {
                handleCheckPrintBox(e);
            }
            setIsActive(false);

        }
        catch (err) {
            await error();
            errorMessage(err);
            setIsActive(false);
        }
    }


    const handleCheckPrintBox = (e) => {
        Modal.confirm({
            title: "Print box ean?",
            content: "if not exists, it'll create",
            okText: "Print",
            cancelText: "Close",
            onOk: handlePrintBox(e)
        });
    };

    const handleBoxModalHide = () => {
        setBoxModalShow(false);
    };

    const handleDeleteBox = async (boxId) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...deleteBox, paramObject: { boxId: boxId } })
            if (response.status === 200) {
                success()
                deleteSuccess();
                setIsActive(false);
                RecoilRefresh();

            } else {
                error()
                deleteError();
                setIsActive(false);
            }
        }
        catch (err) {
            error()
            errorMessage(err);
            setIsActive(false);
        }
    }

    const deleteSuccess = () => {
        Modal.success({content: 'Successfully deleted'});
    }


    const deleteError = () => {
        Modal.error({content: 'Error! Box cannot be delete!'});
    }

    const handleSearch = event => {
        console.log("search event", event.target.value);
        setSearch(event.target.value);
        if (!!gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.columnApi.autoSizeColumns();
    };

    const { documentItems: { documentItems } = {} } = gridData;

    const inProgress = Enumerable.from(documentItems)
        .where(w => w.state === "in progress")
        .toArray()

    // const done = Enumerable.from(documentItems)
    //     .where(w => w.state === "OK")
    //     .toArray()

    const handleSortingChange = e => {
        if (!!e)
            setFilteredData(inProgress);
        else
            setFilteredData(documentItems);
    }

    UseScannerDetection({ onError, onComplete });


    return (
        <LoadingOverlay active={isActive} >
            <div className="mobile-stock-in">
                    <div style={{ textAlign: 'center', margin: 'auto', marginBottom: '0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                            <Input size="small" placeholder="Search" onChange={handleSearch} value={search || ''} style={{ width: '40%', border: 'none', borderBottom: '0.1px solid #d9d9d9' }} />
                            <Switch
                                defaultChecked={true}
                                checkedChildren="In progress"
                                unCheckedChildren="All"
                                onChange={handleSortingChange}
                                style={{ marginTop: '7px' }}
                            />
                            <Button type="primary" onClick={onPrintButtonClick} className="print-button">Print all</Button >
                            <Modal
                                title="Print all boxes?"
                                visible={isModalVisible}
                                onOk={handlePrintAll}
                                onCancel={handleCancel}
                                okText="Print all"
                                cancelText="Close"
                                okType="primary">
                                <p>Do you really want to print all boxes?</p>
                            </Modal>
                        </div>
                        <CreateBoxModal
                            splitSubmit={splitSubmit}
                            submit={handlePrintBox}
                            show={createBoxModalShow}
                            data={createBoxData}
                            items={documentItems}
                            close={handleCreateBoxModalHide}
                        />
                        <DocumentDetailPickBoxModal
                            show={boxModalShow}
                            onHide={handleBoxModalHide}
                            data={box}
                            handleScanner={onComplete}
                        />
                    </div>
                    <div>
                        {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                        <div ref={wrapper}
                            className="ag-theme-material"
                            style={{ width: '98%', justifyContent: 'center', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}
                        >

                            <AgGrid
                                columnDefs={options.columnDefs}
                                rowData={filteredData}
                                defaultColDef={options.defaultColDef}
                                // floatingFilter={true}
                                suppressRowTransform={true}
                                autoGroupColumnDef={options.autoGroupColumnDef}
                                // getRowStyle={getRowStyle}
                                //   sideBar={options.sideBar}
                                onCellClicked={onCellClicked}
                                handleDeleteBox={handleDeleteBox}
                                context={{
                                    componentParent: this
                                }}
                                frameworkComponents={{
                                    deleteButtonRenderer: DeleteButtonRenderer,
                                    rowSpanRenderer: RowSpanRenderer
                                }}
                                getRowNodeId={data => data.id}
                                onGridReady={onGridReady}
                                gridOptions={options.gridOptions}
                            // domLayout={'autoHeight'}
                            >
                            </AgGrid>

                        </div>
                        {/* </Loader> */}
                    </div>
                <NavBar />
            </div>

        </LoadingOverlay>
    )
}