import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '58ec973f-9c94-4e25-8019-d22e334fc973',
  clientId: 'e0b15c01-7434-419a-b0ab-ab217a6443f9',
  endpoints: {
    api: 'e0b15c01-7434-419a-b0ab-ab217a6443f9',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
