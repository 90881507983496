import moment from 'moment';

const options = {
    columnDefs: [
            {
                headerName: "Position",
                field: "position.label",
                filter: "agTextColumnFilter", 
                width: 40,
            },
            {
                headerName: "Ean",
                field: "position.ean",
                filter: "agTextColumnFilter", 
                width: 50,
            },
            {
                headerName: "Floor",
                field: "position.floor",
                filter: "agTextColumnFilter", 
                width: 30,
            },
            { 
                headerName: 'Line', 
                field: 'position.line', 
                filter: "agTextColumnFilter",
                width: 30, 
            },
            {
                headerName: "Shelf",
                field: "position.shelf",
                filter: "agTextColumnFilter", 
                width: 30,
            },
            {
                headerName: "Created",
                field: "createdDate",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm:ss"),
                width: 80
            },
            {
                headerName: "Inventory",
                field: "inventoryDate",
                filter: "agTextColumnFilter",
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm:ss"),
                width: 80,
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.inventoryDate != null },
    },
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};





export default options;