import * as React from "react";
import BarcodeInput from "@bit/hlouzek.rhplus.barcode-input";
import usePage from "@bit/hlouzek.rhplus.page/dist/page";
import { Modal, Affix } from "antd";
import BoxContent from "./components/BoxContent";
import StockMovements from "./components/StockMovements";
import Enumerable from "linq";
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import { useLoaderContext } from "@bit/hlouzek.rhplus.loader/dist/reducer/Context";
import LoadingOverlay from 'components/LoadingOverlay';

export default function LaptopInfo() {
  const { setPageTitle } = usePage();
  const { callApi, errorCatch } = useApi(
    process.env.REACT_APP_COMPANYPOINT_PROXY
  );
  const { getInfo, productStockMovementsChart } = apis;
  const { loading, setLoading, key } = useLoaderContext();
  const [info, setInfo] = React.useState({});
  const [label, setLabel] = React.useState("");
  const [chartMovements, setChartMovements] = React.useState({});
  const [contentVisible, setContentVisible] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [top, setTop] = React.useState(30);

  React.useEffect(() => {
    setPageTitle("Wms - Info");
  }, []);

  const onComplete = (e) => {
    console.log("success", e);
    loadData(e);
  };

  const onError = (e) => {
    console.log("error", e);
  };

  const loadData = async (ean) => {
    setIsActive(true);
    try {
      const response = await callApi({ ...getInfo, paramObject: { ean: ean } });
      const result = response.data;
      console.log("result", response.data);
      setInfo(result);
      setContentVisible(true);
      setIsActive(false);

      if (result.position != null) setLabel(result.position.label);
      if (result.box != null) {
        loadChartMovements(result.box.product.productId);
      }
    } catch (err) {
      loadingError(ean);
      setIsActive(false);
    }
  };

  const loadingError = (ean) => {
    Modal.error({
      title: "Error",
      content: `Cannot find ean ${ean}`,
    });
  };

  const loadChartMovements = async (productId) => {
    setLoading(true);
    try {
      const response = await callApi({
        ...productStockMovementsChart,
        paramObject: { productId: productId },
      });
      const result = response.data;
      setChartMovements(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const chartData = Enumerable.from(chartMovements.legends)
    .zip(chartMovements.values, (left, right) => ({
      legends: left,
      values: right,
    }))
    .toArray();

  return (
    <LoadingOverlay isActive={isActive}>
      <div className="info">
        <Affix offsetTop={top}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              marginTop: "-80px",
            }}
          >
            <h3 style={{ paddingTop: "70px" }}>Scan barcode</h3>
            <BarcodeInput
              onSearch={onComplete}
              onError={onError}
              searchProperties={{
                style: {
                  width: 450,
                  marginBottom: "20px",
                },
              }}
              showSearchButton={true}
              showBarcodeButton={true}
              hideCameraAfterDetection={true}
              cameraPosition="down"
              cameraClassName="mt-3"
              className="barcode-input"
              barcodeIconStyle="barcode-icon"
            />
          </div>
        </Affix>
        <div
          style={{
            width: "80%",
            textAlign: "left",
            margin: "auto",
            marginTop: "10px",
          }}
        >
          <BoxContent
            visible={contentVisible}
            data={info}
            chartData={chartData}
          />
          <StockMovements visible={contentVisible} data={info.box} />
        </div>
      </div>
    </LoadingOverlay>
  );
}
