import React from 'react';
import ReactLoading from 'react-loading';

class LoadingBar extends React.Component {
  render() {
    return (
      <div className="mt-5">
        <ReactLoading className="container" type={"spin"} color={"#f96332"} height="12vh" width="12vh" />
        <h3 style={{ textAlign: "center" }}>Loading data</h3>
      </div>
    );
  }  
}

export default LoadingBar;
