import * as React from 'react';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';

export const FetchInventoryQueue = async () => {
  const { inventoryQueue } = apis;
  const { callApi } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);  
  const response = await callApi({ ...inventoryQueue });
  return response;
  
}


