import * as React from 'react';
import { Input, Space, Switch, Button } from 'antd';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import 'antd/dist/antd.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { useRecoilValue, useRecoilState } from "recoil";
import { stockInDocumentsFilterState } from "./recoil/selectors";
import { stockInFilterAtom } from './recoil/atoms';
import { stockInDocumentsSortingEnum } from './recoil/constants';
import { AudioOutlined } from '@ant-design/icons';
import { useSpeechRecognition } from 'react-speech-kit';


const { Search } = Input;
export default function LaptopStockIn() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { setPageTitle } = usePage();
    const [searchLoading, setSearchLoading] = React.useState(false);
    const { loading, setLoading, key } = useLoaderContext();
    const data = useRecoilValue(stockInDocumentsFilterState);
    const [stockInFilter, setStockInFilter] = useRecoilState(stockInFilterAtom);
    const [search, setSearch] = React.useState('');
    const [gridApi, setGridApi] = React.useState({});
    const [columnApi, setColumnApi] = React.useState({});
    const [value, setValue] = React.useState('');
    const { listen, listening, stop } = useSpeechRecognition({
        onResult: (result) => {
            console.log("listen result", result);
          setValue(result);
        }
      });


    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Wms - Stock In");
    }, []);


    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#46c78f',
            }}
            onMouseDown={()=>listen({ interimResults: true, lang: "cs-CZ" })} 
            onMouseUp={stop}
            
        />
    );
  

    const onSearch = event => {
        console.log("search event", event);
        setValue(event.target.value)
        // if (!!gridApi) {
        //     gridApi.setQuickFilter(event.target.value);
        // }
        
    }

    const onSearch2 = () => {
        if (!!value) {
            if (!!LaptopStockIn.gridApi) {
                LaptopStockIn.gridApi.setQuickFilter(value);
            }    
        }
    }



    const onGridReady = params => {
        LaptopStockIn.gridApi = params.api;
        LaptopStockIn.columnApi = params.columnApi;
        params.columnApi.autoSizeColumns();
    };


    const handleSortingChange = e => {
        if (!!e)
            setStockInFilter({ ...stockInFilter, state: stockInDocumentsSortingEnum.BY_FINISHED });
        else
            setStockInFilter({ ...stockInFilter, state: stockInDocumentsSortingEnum.BY_LABEL });
    }

    React.useEffect(() => {
        onSearch2()
    }, [value])

    return (
        <div className="stock-in">
            <div className="input" style={{ display: 'flex' }}>
                <Space direction="vertical">
                    <Search
                        placeholder="Document number"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onChange={onSearch}
                        suffix={suffix}
                        value={value || ""}
                    />
                </Space>
                <Switch
                    defaultChecked={true}
                    checkedChildren="In progress"
                    unCheckedChildren="All"
                    onChange={handleSortingChange}
                    style={{ width: '100px', marginLeft: '30px', marginTop: '9px' }}
                />

            </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
            <div ref={wrapper}
                className="ag-theme-balham"
                style={{ width: '99%', marginTop: '30px', marginLeft: '10px' }}
            >
                <AgGrid
                    columnDefs={options.columnDefs}
                    rowData={data}
                    defaultColDef={options.defaultColDef}
                    floatingFilter={true}
                    autoGroupColumnDef={options.autoGroupColumnDef}
                    //   sideBar={options.sideBar}
                    context={{
                        componentParent: this
                    }}
                    rowClassRules={options.rowClassRules}
                    frameworkComponents={options.frameworkComponents}
                    onGridReady={onGridReady}
                >
                </AgGrid>
            </div>
            {/* </Loader> */}
        </div>
    )
}