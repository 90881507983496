import * as React from 'react';
import LaptopDelivery from './LaptopVersion';
import MobileDelivery from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Delivery() {

    return (
        <div>
            <React.Suspense
                fallback={<Loader
                    type="Oval"
                    color="#46c78f"
                    height={60}
                    width={60}
                    timeout={3000} //3 secs
                    style={{ textAlign: 'center', margin: 'auto', marginTop: '70px' }}
                />}>
                <div>
                    <ShowAt breakpoint="xlarge" >
                        <LaptopDelivery />
                    </ShowAt>
                    <ShowAt breakpoint="large">
                        <LaptopDelivery />
                    </ShowAt>
                    <ShowAt breakpoint="medium">
                        <MobileDelivery />
                    </ShowAt>
                    <ShowAt breakpoint="small">
                        <MobileDelivery />
                    </ShowAt>
                </div>
            </React.Suspense>
        </div>
    )
}