const options = {
    columnDefs: [
        {
            headerName: "Qty",
            field: "ordered",
            width: 50,
            cellRenderer: 'stockoutQuantityRenderer'
            // cellClassRules: {
            //     'green-outer': function(params) { return params.data.ordered - params.data.stockOut === 0; },
            // }
        },
        {
            headerName: "Product",
            field: "product",
            filter: "agTextColumnFilter",
            width: 70,
            autoHeight: true,
            wrapText: true
        },
        {
            headerName: "Description",
            field: "description",
            width: 130,
            autoHeight: true,
            wrapText: true
        },
        {
            headerName: "Position",
            field: "positions",
            width: 80,
            cellClassRules: {
                'green-stock-outer': function(params) { return params.data.inStock > params.data.ordered && params.data.stockOut === 0 || params.data.inStock === params.data.ordered},
                'yellow-stock-outer': function(params) {return params.data.ordered > params.data.inStock && params.data.inStock != 0 && params.data.stockOut === 0},
                'red-stock-outer': function(params) {return params.data.inStock === 0 && params.data.stockOut === 0}
            },
            cellRenderer: params => {
                const { palette, positions } = params.data;
                if(!!palette) {
                    return `<span>${palette}</span>`
                } else {
                    return `<span>${positions}</span>`
                }
                  
            }
        }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
    },
    rowHeight: 150,
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};



export default options;