import * as React from 'react';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { Input, Space } from 'antd';
import { useRecoilValue, useRecoilState } from "recoil";
import { deliveryDocumentsFilterState } from "./recoil/selectors";
import { deliveryFilterAtom } from './recoil/atoms';


const { Search } = Input;

export default function LaptopDelivery() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const data = useRecoilValue(deliveryDocumentsFilterState);
    const [deliveryFilter, setDeliveryFilter] = useRecoilState(deliveryFilterAtom);

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Wms - Delivery")
    }, []);

   

    const onCellDoubleClicked = e => {
        const { id } = e.data;
        window.location = `/app/wms/delivery/${id}`;
      }

    const onProjectNameSearch = value => {
        setDeliveryFilter({...deliveryFilter, projectName: value});
    }

    const onAddressSearch = value => {
        setDeliveryFilter({...deliveryFilter, address: value});
    }

   


    return (
        <div className="delivery">
             <div className="input" style={{display: 'flex'}}>
                <Space direction="vertical" style={{marginRight: '30px'}}>
                    <Search
                        placeholder="Project name"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={onProjectNameSearch}
                    />
                </Space>
                <Space direction="vertical">
                    <Search
                        placeholder="Address"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={onAddressSearch}
                    />
                </Space>

            </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                <div ref={wrapper}
                    className="ag-theme-balham"
                    style={{ width: '99%', height: '100%', marginTop: '10px', marginLeft: '10px' }}
                >
                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data}
                        defaultColDef={options.defaultColDef}
                        floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        onCellDoubleClicked={onCellDoubleClicked}
                    >
                    </AgGrid>
                </div>
            {/* </Loader> */}

        </div>
    )
}
