import * as React from 'react';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import DeleteButtonRenderer from './components/DeleteButtonRenderer';
import { Modal, Button } from 'antd';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import LoadingOverlay from 'components/LoadingOverlay';
import { PALLET_PURPOSE, DELIVERY_PURPOSE } from "types/purposes";
import { useRecoilValue, useRecoilState } from "recoil";
import { addPalletToDeliveryAtom } from './recoil/atoms';


export default function LaptopDeliveryDetail({ search, data, deliveryCode }) {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const [isActive, setIsActive] = React.useState(false);
    const { setPageTitle } = usePage();
    const { success, error } = useBeeper();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { simplePositionInfo, assignPalletToDelivery } = apis;
    const [addPalletToDelivery, setAddPalletToDelivery] = useRecoilState(addPalletToDeliveryAtom);
    const [state, setState] = React.useState({
        title: "Pick delivery barcode",
        assignedDelivery: {},
        isLoading: true
    })


    const id = deliveryCode;

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Wms - Delivery Detail")
    }, []);

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const onComplete = code => {
        console.log("COMPLETE! ", code);
        loadSimplePositionInfo(code);

    }

    const loadSimplePositionInfo = async (code) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...simplePositionInfo, paramObject: {ean: code} })
            const { position, project, deliveryPosition } = response.data;
            switch (position.purpose) {
                case DELIVERY_PURPOSE:
                  if (deliveryPosition.projectId != id) {
                    error();
                    Modal.error({
                        title: "Error",
                        content: "Picked position does not belong to this delivery!",
                    });
                    setIsActive(false);
                    return;
                  }
                  // uzavreni delivery
                  if (
                    state.assignedDelivery.position &&
                    state.assignedDelivery.position.id ===
                      position.id
                  ) {
                    this.props.history.push({
                      pathname: "/Wms/Delivery/CloseDelivery",
                      state: {
                        delivery: this.state.assignedDelivery,
                      },
                    });
                  }
      
                  setState({
                    title: `${position.label} vybrán. Přiřaďte stockout palety`,
                    assignedDelivery: response.data,
                  });
                  setIsActive(false);
                  break;
      
                case PALLET_PURPOSE:
                  if (state.assignedDelivery.position === undefined) {
                    error();
                    Modal.error({
                        title: 'Error',
                        content: "Pípni nejdříve delivery štítek",
                    });
                    setIsActive(false);
                    return;
                  }
                  if (
                    deliveryPosition.projectId > 0 &&
                    id != deliveryPosition.projectId
                  ) {
                    error();
                    Modal.error({
                        title: 'Error',
                        content: `Paleta je přiřazena k ${
                        project && project.projectName
                      } projektu!`,
                    });
                    setIsActive(false);
                    return;
                  }
      
                  if (
                    position.parentId != null &&
                    state.assignedDelivery.position.id
                  ) {
                    error();
                    Modal.error({
                        title: 'Error',
                        content: `Out paleta je přiřazena k delivery ${state.assignedDelivery.position.label}`,
                    });
                    setIsActive(false);
                    return;
                  }
      
                  if (position.parentId == null) {
                    if (state.assignedDelivery.deliveryPosition.closed != null) {
                      success();
                      Modal.success({
                        content: "Delivery has been already closed",
                      });
                      setIsActive(false);
                      return;
                    }
      
                    try {
                        const palleteResponse = await callApi({ ...assignPalletToDelivery, paramObject: {palletEan: code, deliveryId: state.assignedDelivery.position.id}})
                        success();
                        setAddPalletToDelivery({pallets: [palleteResponse.action.pallet, ...palleteResponse.pallets]});
                        setIsActive(false);
                    } catch (err) {
                        error();
                        Modal.error({
                            title: 'Error',
                            content: {err}
                        });
                        setIsActive(false);
                    }
                  }
                  break;
              }
        } catch (err) {
            error();
            Modal.error({
                title: 'Error',
                content: {err}
            });
            setIsActive(false);
        }
    }


    const getRowStyle = params => {
        if (params.data.closed != "") {
            return { background: '#c3e6cb' };
        }
    };

    const createDeliveryPage = id => {
        const projectId = id
        window.location = `/app/wms/delivery/create-delivery/${projectId}`;
    }

    UseScannerDetection({ onError, onComplete });

    return (
        <LoadingOverlay active={isActive}>
            <div className="delivery-detail" style={{ width: '70%', justifyContent: 'center', margin: 'auto' }}>
                <CardBox styleName="mb-3" heading="">
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: "space-between", minHeight: '100px' }}>
                            <div style={{ textAlign: 'left' }}>
                                <h4>{data.project}</h4>
                                <p>{data.address}</p>
                            </div>
                            <Button type="primary" className="create-delivery-button" onClick={() => createDeliveryPage(id)}>Create delivery</Button>

                        </div>
                        <h3>{state.title}</h3>
                    </div>
                </CardBox>
                <div ref={wrapper}
                    className="ag-theme-balham ag-delivery-detail"
                    style={{ width: '100%', marginTop: '10px' }}
                >

                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data.pallets}
                        defaultColDef={options.defaultColDef}
                        floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        getRowStyle={getRowStyle}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        frameworkComponents={{
                            deleteButtonRenderer: DeleteButtonRenderer,
                        }}
                        rowHeight={37}
                    >
                    </AgGrid>
                </div>

            </div>
        </LoadingOverlay>
    )


}