import * as React from 'react';
import { Form, Modal, Switch, InputNumber } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const DocumentDetailPickBoxModal = React.forwardRef((props, ref) => {
    const [originBoxQuantity, setOriginBoxQuantity] = React.useState('');
    const [boxQuantity, setBoxQuantity] = React.useState(null);
    const [confirmVisible, setConfirmVisible] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [splitItem, setSplitItem] = React.useState(false);
    const [form] = Form.useForm();

    React.useEffect(() => {
        const { show, stockOut, items, boxQuantity } = props;
        if (show) {
            const _boxQuantity = Math.min(stockOut, boxQuantity);

            form.setFieldsValue({ quantity: items.length === 1 ? _boxQuantity : null });
            setOriginBoxQuantity(items.length === 1 ? _boxQuantity : '');
            setSelectedId(items.length === 1 ? items[0].id : null);
        }
    }, [props.show])

    console.log("modal box", props);

    const changeSplitItem = checked => {
        setSplitItem(checked);
    }

    const submit = barcode => props.submit(form.getFieldsValue(), selectedId, barcode);

    React.useImperativeHandle(ref, () => ({
        submit
    }));

    const handleSelectItem = item => {
        setSelectedId(item.id);
        form.setFieldsValue({ quantity: Math.min(item.remainStockOut, props.boxQuantity)});
    }


    const handleColorItem = id => {
        if (id === selectedId) {
            return {
                backgroundColor: 'red'
            }
        }
    }

    const { show, stockOut, items } = props;
    return (
        <Modal
            title="Scan out position"
            visible={show}
            footer={null}
        >
            <h6>{props.product}</h6>
            <div>{props.description}</div>
            {
                items && items.length > 1 && (
                    <div>
                        <div className="font-weight">Choose document item</div>
                        <table hover className="mt-4">
                            <thead>
                                <tr>
                                    <td>Item</td>
                                    <td>Ordered</td>
                                    <td>Remain</td>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr style={handleColorItem(item.id)} onClick={e => handleSelectItem(item)}>
                                        <td>{`item ${index}`}</td>
                                        <td className="text-center">{item.ordered}</td>
                                        <td className="text-center">{item.remainStockOut}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
            {console.log("quantitz", props.stockOut)}
            <Form
                onSubmit={submit}
                form={form}
                initialValues={{
                    quantity: stockOut,
                    splitItemChecked: splitItem
                }}
            >
                <table hover className="mt-4">
                    <tbody>
                        <tr>
                            <td>Quantity</td>
                            <td>
                                <Form.Item name="quantity" rules={[{ required: true, message: "Povinná hodnota" }]}>
                                    <InputNumber style={{ borderRadius: '50px' }} />
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td>Position</td>
                            <td>{props.position}</td>
                        </tr>
                        <tr>
                            <td>Split item</td>
                            <td>
                                <Form.Item name="splitItem">
                                    <Switch
                                        onChange={changeSplitItem}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        style={{ marginLeft: '10px', marginTop: '5px' }} />
                                </Form.Item>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Form>
        </Modal>
    )
});

export default DocumentDetailPickBoxModal;