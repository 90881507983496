import * as React from 'react';
import LaptopDeficitExcessDetail from './LaptopVersion';
import MobileDeficitExcessDetail from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';
import {favicons, useFavicon} from '@bit/hlouzek.rhplus.favicon';

function getTitleEl() {
    return document.getElementById("app-title");
  }

export default function StockIn() {
    useFavicon({url: favicons.wms.deficitExcess});

    const titleChange = () => {
        const title = getTitleEl();
        console.log("title", title);
        // favicon.href = `${process.env.PUBLIC_URL} + /favicons/stockInIcon.ico`;
        title.innerHTML = "Deficit/Excess"
      };
    
      React.useEffect(() => {
        titleChange();
    }, []);
    
    return (
        <div>
            <ShowAt breakpoint="xlarge" >
                <LaptopDeficitExcessDetail />
            </ShowAt>
            <ShowAt breakpoint="large">
                <LaptopDeficitExcessDetail />
            </ShowAt>
            <ShowAt breakpoint="medium">
                <MobileDeficitExcessDetail />
            </ShowAt>
            <ShowAt breakpoint="small">
                <MobileDeficitExcessDetail />
            </ShowAt>
        </div>
    )
}