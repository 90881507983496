const options = {
    columnDefs: [
            {
                headerName: "Sum",
                field: "ordered",
                width: 100,
                filter: "agTextColumnFilter", 
                rowSpan: params => params.data.mergeItems,
                cellClassRules: {
                    'cell-span': function(params) {return params.data.mergeItems > 1 || params.data.mergeItems === null },
                  },
                cellRenderer: "rowSpanRenderer"
            },
            {
                headerName: "Qty",
                field: "boxed",
                width: 100,
                filter: "agTextColumnFilter",
                cellClassRules: {
                    'rag-red-outer': function(params) {return params.data.boxEanShort != null && params.data.state === "in progress"},
                    'rag-green-outer': function(params) { return params.data.position != undefined || params.data.state.toUpperCase() === "OK" || params.data.boxedColor === "table-success"},
                },
            },
            {
                headerName: "Product",
                field: "product",
                width: 100,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Description",
                field: "description",
                width: 250,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Position",
                field: "positionName",
                width: 150,
                filter: "agTextColumnFilter"
            },
            {
                headerName: "Code",
                field: "boxEanShort",
                width: 80,
                filter: "agTextColumnFilter"
            },
            {
                headerName: "",
                field: "actions",
                width: 40,
                cellRenderer:"deleteButtonRenderer",
                cellClass: "grid-cell-centered"

            },
            {
                headerName: "State",
                field: "state",
                width: 100,
                filter: "agTextColumnFilter"
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};

// function rowSpan(params) {
//     var product = params.data.product;
//     if (product === product) {
//       return 2;
//     } 
//   }


export default options;