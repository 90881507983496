
const companypointDataHeaders = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
}

const stockInDocuments = {
    url: `wms/stockin/document`,
    methodType: "POST",
    version: "1.0",
}

const stockInDocumentDetail = (documentNumber) => {
  return {
    url: `Wms/StockIn/DocumentItem/${documentNumber}`,
    methodType: "POST",  
    version: "1.0"
  }
}

const stockInBoxInfo = {
  url: `Wms/StockIn/StockInBoxInfo`,
  methodType: "POST",
  version: "1.0"
}

const stockInToBox = {
  url: `Wms/StockIn/StockInToBox`,
  methodType: "POST",
  version: "1.0"
}

const getDocumentItemById = {
  url: `wms/wmsinfo/getdocumentitembyid`,
  methodType: "POST",
  version: "1.0"
}

const stockInToPosition = {
  url: `Wms/StockIn/StockInToPosition`,
  methodType: "POST",
  version: "1.0"
}

const mergeStockInItems = {
  url: `wms/stockin/mergestockinitems`,
  methodType: "POST",
  version: "1.0"
}

const infoBeforeCreateBox = {
  url: `wms/stockin/infobeforecreatebox`,
  methodType: "POST",
  version: "1.0"
}

const printBox = {
  url: `Wms/Box/PrintBox`,
  methodType: "POST",
  version: '1.0'
}

const printAll = {
  url: `Wms/StockIn/PrintAll`,
  methodType: "POST",
  version: "1.0"
}

const deleteBox = {
  url: `wms/box/deletebox`,
  methodType: "POST",
  version: "1.0"
}

const stockOutDocumentDetail = (documentNumber) => {
  return {
    url: `Wms/StockOut/DocumentItem/${documentNumber}`,
    methodType: "POST",
    version: "1.0"
  }
}

const stockOutBoxInfo = {
  url: `Wms/StockOut/StockOutBoxInfo`,
  methodType: "POST",
  version: "1.0"
}

const stockOutFromPosition = {
  url: `Wms/StockOut/StockOutFromPosition`,
  methodType: "POST",
  version: "1.0"
}

const stockOutDocuments = {
  url: `Wms/StockOut/Document`,
  methodType: "POST",
  version: "1.0"
}

const productPlacementInfo = {
  url: `wms/product/productplacementinfo`,
  methodType: "POST",
  version: "1.0"
}

const productDetail = {
  url: `erp/product/productdetail`,
  methodType: "POST",
  version: "1.0"
}

const getProductSpecificationInventory = {
  url: `wms/product/getproductspecificationinventory`,
  methodType: "GET",
  version: "1.0"
}

const documentItemInventory = {
  url: `documents/documentiteminventory`,
  methodType: "POST",
  version: "1.0"
}

const inventoryQueue = {
  url: `wms/inventory/getinventoryqueue`,
  methodType: "POST",
  version: "1.0"
}

const deliveryProjects = {
  url: `wms/delivery/deliveryprojects`,
  methodType: "POST",
  version: "1.0"
}

const deliveryProjectPallets = {
  url: `wms/delivery/projectpallets`,
  methodType: "POST",
  version: "1.0"
}

const removePalletFromDelivery = {
  url: `Wms/Delivery/RemovePalletFromDelivery`,
  methodType: "POST",
  version: "1.0"
}

const createDelivery = {
  url: `Wms/Delivery/CreateDelivery`,
  methodType: "POST",
  version: "1.0"
}


const shortageSummary = {
  url: `api/pages/shortages/summary`,
  methodType: "POST",
  version: "1.0",
}

const templateDynamicData = {
  url: `api/Template/DynamicData`,
  methodType: "POST",
  version: "1.0"
}

const getInfo = {
  url: `wms/wmsinfo/getinfo`,
  methodType: "POST",
  version: "1.0"
}

const productStockMovementsChart = {
  url: `product/productstockmovementschart`,
  methodType: "POST",
  version: "1.0"
}

const boxInfo = {
  url: `Wms/Box/GetBox`,
  methodType: "POST",
  version: "1.0"
}

const documentInventory = {
  url: `wms/Inventory/BoxInventory`,
  methodType: "POST",
  version: "1.0"
}

const getBoxInfo = {
  url: `wms/wmsinfo/getboxinfo`,
  methodType: "POST",
  version: "1.0"
}

const infoPrintBox = {
  url: `wms/wmsInfo/printBox`,
  methodType: "POST",
  version: "1.0"
}

const splitBox = {
  url: `wms/split/splitbox`,
  methodType: "POST",
  version: "1.0"
}

const relocateBox = {
  url: `wms/relocate/relocatebox`,
  methodType: "POST",
  version: "1.0"
}

const regroupingBox = {
  url: `wms/regrouping/regroupingbox`,
  methodType: "POST",
  version: "1.0"
}

const simplePositionInfo = {
  url :`Wms/Position/SimplePositionInfo`,
  methodType: "POST",
  version: "1.0"
}

const assignPalletToDelivery = {
  url: `Wms/Delivery/AssignPalletToDelivery`,
  methodType: "POST",
  version: "1.0"
}

export const apis = {
  stockInDocuments,
  stockInDocumentDetail,
  stockInBoxInfo,
  stockInToBox,
  getDocumentItemById,
  stockInToPosition,
  mergeStockInItems,
  infoBeforeCreateBox,
  printAll,
  printBox,
  deleteBox,
  stockOutDocuments,
  stockOutDocumentDetail,
  stockOutBoxInfo,
  stockOutFromPosition,
  productPlacementInfo,
  productDetail,
  getProductSpecificationInventory,
  documentItemInventory,
  inventoryQueue,
  deliveryProjects,
  deliveryProjectPallets,
  removePalletFromDelivery,
  createDelivery,
  shortageSummary,
  templateDynamicData,
  getInfo,
  productStockMovementsChart,
  boxInfo,
  documentInventory,
  getBoxInfo,
  infoPrintBox,
  splitBox,
  relocateBox,
  regroupingBox,
  simplePositionInfo,
  assignPalletToDelivery
}
