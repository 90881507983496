import * as React from 'react';
import base64 from 'base-64';
import utf8 from 'utf8';

export const GenerateToken = userName => {
  var text = new Date().toJSON().slice(0,10).replace(/-/g,'5') + userName;
  var bytes = utf8.encode(text);
  var encoded = base64.encode(bytes);
  return encoded;
}

export const IsValidToken = token => {
  var text = new Date().toJSON().slice(0,10).replace(/-/g,'5') + process.env.REACT_APP_USER_NAME;
  var bytes = utf8.encode(text);
  var encoded = base64.encode(bytes);
  return (encoded == token);
}