import { selector } from 'recoil';
import { FetchDocuments } from './data';
import Enumerable from 'linq';
import { stockInFilterAtom } from './atoms';
import { stockInDocumentsSortingEnum } from './constants';
import { IN_PROGRESS } from 'constants/common';
import moment from 'moment';

export const stockInDocumentsSelector = selector({
  key: "stockInDocumentsSelector",
  get: async () => {
    const response = (await FetchDocuments())
      .data.result.documents;
    return response;
  },
  dangerouslyAllowMutability: true
})


export const stockInDocumentsFilterState = selector({
  key: "stockInDocumentsFilterState",
  get: ({get}) => {
    let items = get(stockInDocumentsSelector);
    const {state, documentNumber} = get(stockInFilterAtom);
    
    if (!!documentNumber) {
      items =  items.filter(f => f.documentNumber === documentNumber);
        return items;
    }

    switch (state) {
      case stockInDocumentsSortingEnum.BY_FINISHED:
        const data = Enumerable.from(items)
          .where(w => w.state === IN_PROGRESS)
          .orderByDescending(o => moment(o.createDate))
          .toArray();
        return data;

      case stockInDocumentsSortingEnum.BY_LABEL:
        return Enumerable.from(items)
          .orderByDescending(o => moment(o.createDate))
          .toArray();

    }
    return [];
  },
  dangerouslyAllowMutability: true
})

