const options = {
    columnDefs: [
        {
            headerName: "Sum",
            field: "ordered",
            width: 39,
            lockPosition: true,
            rowSpan: params => params.data.mergeItems,
            cellClassRules: {
                    'cell-span': function(params) {return params.data.mergeItems > 1 || params.data.mergeItems === null },
                  },
            cellRenderer: "rowSpanRenderer"
        },
        {
            headerName: "Qty",
            field: "boxed",
            width: 37,
            cellClassRules: {
                'rag-red-outer': function(params) {return params.data.boxEanShort != null && params.data.state === "in progress"},
                'rag-green-outer': function(params) { return params.data.position != undefined || params.data.state.toUpperCase() === "OK" || params.data.boxedColor === "table-success"},
            },
            lockPosition: true
        },
        {
            headerName: "Product",
            field: "product",
            width: 62,
            wrapText: true,
            lockPosition: true,
        },
        {
            headerName: "Description",
            field: "description",
            width: 90,
            wrapText: true,
            lockPosition: true,
            autoHeight: true
        },
        {
            headerName: "Position",
            field: "positionName",
            width: 60,
            lockPosition: true
        },
        {
            headerName: "Code",
            field: "boxEanShort",
            width: 60,
            lockPosition: true
        },
        {
            headerName: "",
            field: "actions",
            width: 30,
            cellRenderer:"deleteButtonRenderer",
            cellClass: "grid-cell-centered",
            lockPosition: true

        },
        {
            headerName: "State",
            field: "state",
            width: 48,
            lockPosition: true
        }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        filter: false,
    },
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};





export default options;
