import * as React from 'react';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import 'antd/dist/antd.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import { useRecoilValue, useRecoilState } from "recoil";
import { sortedProductSpecificationInventoryState } from "./recoil/selectors";
import { productSpecificationInventorySortingType } from './recoil/atoms';
import { productSpecificationInventorySortingTypeEnum } from './recoil/constants';
import { Switch } from 'antd';

export default function LaptopProduct() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const data = useRecoilValue(sortedProductSpecificationInventoryState);
    const [productSpecificationInventorySorting, setProductSpecificationInventorySorting] = useRecoilState(productSpecificationInventorySortingType);

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Wms - Product")
    }, []);


    const handleSortingChange = e => {
        if (!!e)
        setProductSpecificationInventorySorting(productSpecificationInventorySortingTypeEnum.BY_COMPLETE);
        else
        setProductSpecificationInventorySorting(productSpecificationInventorySortingTypeEnum.BY_LABEL);
    }


    return (
        <div className="product">
            <div style={{display:'flex'}}>
                <h3 style={{ marginLeft: '30px' }}>Product Inventory</h3>
                <Switch
                    checkedChildren="In progress"
                    unCheckedChildren="All"
                    onChange={handleSortingChange}
                    style={{ width: '100px', marginLeft: '30px', marginTop: '9px' }}
                />
            </div>
            <Loader styles={{ height: 'calc(100vh - 100px)' }}>
                <div ref={wrapper}
                    className="ag-theme-balham"
                    style={{ width: '99%', height: '100%', marginTop: '10px', marginLeft: '10px' }}
                >
                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data}
                        defaultColDef={options.defaultColDef}
                        floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        rowClassRules={options.rowClassRules}
                        frameworkComponents={options.frameworkComponents}
                        gridOptions={options.gridOptions}
                    >
                    </AgGrid>
                </div>
            </Loader>
        </div>
    )
}