import React from 'react';

export default function RowSpanRenderer(props) {
    const ordered = props.data.ordered;
    const mergeItems = props.data.mergeItems;

    return (
        <span>
            {mergeItems != null ? (
                <div>{ordered}</div>
            ) : (
                <div />
            )}
        </span>
    )
}
