import moment from 'moment';


const options = {
    columnDefs: [
            {
                headerName: "Delivery",
                field: "delivery",
                width: 80,
                filter: "agTextColumnFilter", 
                cellClass: "grid-cell-centered",
                autoHeight: true  
            },
            {
                headerName: "Pallet",
                field: "pallet",
                width: 80,
                filter: "agTextColumnFilter",
                cellClass: "grid-cell-centered",
                autoHeight: true 
            },
            {
                headerName: "Closed",
                field: "closed",
                cellRenderer: params => !!params.value ? moment(params.value).format("DD.MM.YY HH:mm") : "",
                width: 100,
                filter: "agTextColumnFilter",
                cellClass: "grid-cell-centered",
                autoHeight: true 
            },
            {
                headerName: "Actions",
                field: "actions",
                width: 80,
                filter: "agTextColumnFilter",       
                cellRenderer:"deleteButtonRenderer",
                cellClass: "grid-cell-centered",
                autoHeight: true 
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    gridOptions: {
        getRowClass: function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        }
      }
};



export default options;
