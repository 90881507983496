import React from 'react';
import { BooleanIcon } from '@bit/hlouzek.rhplus.icons';
import isEmpty from 'lodash/isEmpty';
import StockoutQuantity from 'components/StockoutQuantity';
import 'components/StockoutQuantity/stockoutQuantity.css';

export default function StockoutQuantityRenderer(props) {
  const { ordered, remainStockOut, stockOut } = props.data;
  return (
    <div style={{ textAlign: 'center' }}>
      {<StockoutQuantity remainStockOut={remainStockOut} ordered={ordered} stockOut={stockOut} />}
    </div>
  )
}