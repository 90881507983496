import * as React from "react";
import './stockoutQuantity.css';
import classnames from 'classnames';

export default function StockoutQuantity({ remainStockOut, ordered, stockOut, orderedColor = null }) {

    const handleBoxedColor = () => {
        let className;
        if (remainStockOut === 0) className = "green-outer";
        else if (stockOut > 0) className = "table-danger";
        return classnames(["boxes", "text-center", className]);
      };

  return (
    <div className={classnames("stock-number", handleBoxedColor())} >
      <span className="remain">{remainStockOut}</span>
      {remainStockOut !== ordered && ordered !== null ? (
        <span className={classnames("ordered", orderedColor)}>({ordered})</span>
      ) : (
        <div />
      )}
    </div>
  );
}
