import { selector } from 'recoil';
import {FetchInventoryQueue} from './data';
import Enumerable from 'linq';
import {positionsInventoryQueueSortingType} from './atoms';
import {positionsInventoryQueueSortingTypeEnum} from './constants';

export const positionsInventoryQueueState = selector({
  key: "positionsInventoryQueueState",
  get: async () => {
    const response = (await FetchInventoryQueue())
      .data.queueItems;
    return response;
  },
  dangerouslyAllowMutability: true
})

export const sortedPositionsInventoryQueueState = selector({
  key: "sortedPositionsInventoryQueueState",
  get: ({get}) => {
    const items = get(positionsInventoryQueueState);
    const sortingType = get(positionsInventoryQueueSortingType);
    
    switch (sortingType)
    {
      case positionsInventoryQueueSortingTypeEnum.BY_LABEL:
        return items
      case positionsInventoryQueueSortingTypeEnum.BY_INVENTORY:
        return Enumerable.from(items)
          .orderBy(o => !o.inventoryDate ? 1 : 2)
          .thenByDescending(o => o.position.label)
          .toArray();
    }
    return [];
  },
  dangerouslyAllowMutability: true
})
