import * as React from 'react';
import LaptopProduct from './LaptopVersion';
import MobileProduct from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Product() {

    const { loading, setLoading, key } = useLoaderContext();

    return (
        <div>
            <React.Suspense fallback={<Loader
                type="Oval"
                color="#46c78f"
                height={60}
                width={60}
                timeout={3000} //3 secs
                style={{ textAlign: 'center', margin: 'auto', marginTop: '70px' }}
            />}>
                <div>
                    <ShowAt breakpoint="xlarge" >
                        <LaptopProduct />
                    </ShowAt>
                    <ShowAt breakpoint="large">
                        <LaptopProduct />
                    </ShowAt>
                    <ShowAt breakpoint="medium">
                        <MobileProduct />
                    </ShowAt>
                    <ShowAt breakpoint="small">
                        <MobileProduct />
                    </ShowAt>
                </div>
            </React.Suspense>
        </div>
    )
}