import * as React from "react";
import InfoModal from "./InfoModal";
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import LoadingBar from './LoadingBar';


export default function QuantityChange({
  show,
  documentItemId,
  inventoryBox,
  quantity,
  close,
  enableInventory
}) {
  const [qty, setQty] = React.useState(0);
  const [box, setBox] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
  const { getBoxInfo, documentItemInventory } = apis;
  const { loading, setLoading, key } = useLoaderContext();
  const {success, error} = useBeeper();

  React.useEffect(() => {
    loadBoxInfo(inventoryBox);
  }, [inventoryBox]);

  React.useEffect(() => {
    setQty(quantity);
  }, [quantity]);

  const loadBoxInfo = async inventoryBox => {
      setLoading(true);
      if(!!inventoryBox) {
          try {
              const response = await callApi({ ...getBoxInfo, paramObject: {ean: inventoryBox}})
              setBox(response.data);
              setLoading(false)
          } catch (err) {
              setLoading(false);
          }
      }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
        const response = await callApi({ ...documentItemInventory, paramObject: {
            documentItemId,
            boxEan: inventoryBox,
            originQuantity: quantity,
            inventoryQuantity: qty,
            enableInventory
        }})
        success();
        // () => close({quantity: qty});
    } catch (err) {
        error();
        close();
    }
  };

  const handleChange = (e) => {
      setQty(e.target.value);
  };

  const { product: { code, description } = {} } = box;
  return (
    <InfoModal show={show} title={inventoryBox} close={() => close({quantity})}>
      {isLoading ? (
        <div>
          <LoadingBar />
        </div>
      ) : (
        <div>
          <div>
            <b>{code}</b>
          </div>
          <div>{description}</div>
          <form className="mt-1" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Box quantity</label>
              <input
                className="form-control"
                autoComplete="off"
                autoFocus
                name="qty"
                type="number"
                value={qty || ''}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="btn btn-primary btn-block"        
                disabled={(quantity === qty || !qty) && !enableInventory}
              >
                Inventory
              </button>
            </div>
          </form>
        </div>
      )}
    </InfoModal>
  );
}
