const options = {
    columnDefs: [
            {
                headerName: "Label",
                field: "position.label",
                filter: "agTextColumnFilter", 
                width: 45,
            },
            {
                headerName: "Code",
                field: "product.code",
                filter: "agTextColumnFilter", 
                width: 50,
            },
            {
                headerName: "Description",
                field: "product.description",
                filter: "agTextColumnFilter", 
                width: 120,
            },
            { 
                headerName: 'Carton Dimension', 
                field: 'product.packageDimension', 
                filter: "agTextColumnFilter",
                width: 70, 
            },
            {
                headerName: "Product Weight",
                field: "product.weight",
                filter: "agTextColumnFilter", 
                width: 60,
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    gridOptions: {
        getRowClass: function(params) {
          const {
            packageDimension,
            packageWeight,
            emptyPackageWeight,
            sizeType,
            ralCode,
            color,
            material,
            packageQuantity,
            weight 
          } = params.data.product;
          
          if (packageDimension && packageWeight && emptyPackageWeight && sizeType && ralCode && color && material && packageQuantity && weight) {
            return 'table-success';
          }
          else if (packageDimension) {
            return 'table-warning';
          }
          else if ((weight || packageWeight)) {
            return 'table-info';
          }

          if (params.node.rowIndex % 2 === 0) {
            return 'my-shaded-effect';
        }
        }
      }
};





export default options;