import { selector } from 'recoil';
import {FetchDocuments} from './data';
import Enumerable from 'linq';
import {stockOutFilterAtom} from './atoms';
import {stockOutDocumentsSortingEnum} from './constants';
import { IN_PROGRESS } from 'constants/common';
import moment from 'moment';


export const stockOutDocumentsSelector = selector({
  key: "stockOutDocumentsSelector",
  get: async () => {
    const response = (await FetchDocuments())
      .data.result.documents;
    return response;
  },
  dangerouslyAllowMutability: true
})

export const stockOutDocumentsFilterState = selector({
  key: "stockOutDocumentsFilterState",
  get: ({get}) => {
    let items = get(stockOutDocumentsSelector);
    const {state, documentNumber} = get(stockOutFilterAtom);
    
    if (!!documentNumber) {
      items =  items.filter(f => f.documentNumber === documentNumber);
        return items;
    }
      

    switch (state)
    {
      case stockOutDocumentsSortingEnum.BY_LABEL:
        return Enumerable.from(items)
        .orderBy(o => moment(o.createDate))
        .toArray();

      case stockOutDocumentsSortingEnum.BY_FINISHED:
        return Enumerable.from(items)
        .where(w => w.state === IN_PROGRESS)
        .orderBy(o => moment(o.createDate))
        .toArray();
    }
    return [];
  },
  dangerouslyAllowMutability: true
})
