import * as React from 'react';
import { Input, Space, Switch, Affix } from 'antd';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import NavBar from 'components/NavBar';
import { useRecoilValue, useRecoilState } from "recoil";
import { stockOutDocumentsFilterState } from "./recoil/selectors";
import { stockOutFilterAtom } from './recoil/atoms';
import { stockOutDocumentsSortingEnum } from './recoil/constants';




const { Search } = Input;


export default function MobileStockOut() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const data = useRecoilValue(stockOutDocumentsFilterState);
    const [stockOutFilter, setStockOutFilter] = useRecoilState(stockOutFilterAtom);
    const [top, setTop] = React.useState(130);
    const [search, setSearch] = React.useState('');
    const [gridApi, setGridApi] = React.useState({});
    const [columnApi, setColumnApi] = React.useState({});


    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Stock Out");
    }, []);

    // const onSearch = value => {
    //     setStockOutFilter({...stockOutFilter, documentNumber: value });
    // }
    const onSearch = event => {
        console.log("search event", event);
        setSearch(event.target.value);
        if (!!gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.columnApi.autoSizeColumns();
    };

    const handleSortingChange = e => {
        if (!!e)
            setStockOutFilter({ ...stockOutFilter, state: stockOutDocumentsSortingEnum.BY_FINISHED });
        else
            setStockOutFilter({ ...stockOutFilter, state: stockOutDocumentsSortingEnum.BY_LABEL });
    }


    return (
        <div className="mobile-stock-out">
            <div className="mobile-input" >
                <div style={{display: 'flex', justifyContent: 'space-between',  marginLeft: '15px', marginRight: '15px'}}>
                    <Input
                        placeholder="Search"
                        size="small"
                        onChange={onSearch}
                        style={{ width: '60%', border: 'none', borderBottom: '0.1px solid #d9d9d9' }}
                    />
                    <Switch
                        defaultChecked={true}
                        checkedChildren="In progress"
                        unCheckedChildren="All"
                        onChange={handleSortingChange}
                        style={{ width: '100px', margin: 'auto', marginTop: '15px' }}
                    />
                </div>
            </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
            <div ref={wrapper}
                className="ag-theme-material"
                style={{ width: '90%', justifyContent: 'center', margin: 'auto', marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}
            >
                <AgGrid
                    columnDefs={options.columnDefs}
                    rowData={data}
                    defaultColDef={options.defaultColDef}
                    // floatingFilter={true}
                    autoGroupColumnDef={options.autoGroupColumnDef}
                    //   sideBar={options.sideBar}
                    context={{
                        componentParent: this
                    }}
                    rowClassRules={options.rowClassRules}
                    onGridReady={onGridReady}
                    gridOptions={options.gridOptions}

                >
                </AgGrid>
            </div>
            {/* </Loader> */}
            <NavBar />
        </div>
    )
}