import { atom } from 'recoil';


export const stockOutDocumentDetailState = atom({
  key: "stockOutDocumentDetailState",
  default: "",
  dangerouslyAllowMutability: true
});

export const refreshState = atom({
  key: "refreshState",
  default: 0  
});

export const stockOutPickPositionState = atom({
  key: "stockOutPickPositionState",
  default: {
    id: '',
    stockOut: '',
    remainStockOut: '',
    palette: ''
  },
  dangerouslyAllowMutability: true
})


