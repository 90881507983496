import * as React from 'react';


export default function CloseDeliveryPage({match}) {
    console.log("closeDelivery", match);
    return(
        <div>
            test
        </div>
    )
}