import * as React from 'react';
import { Input, Space, Switch } from 'antd';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import 'antd/dist/antd.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import { useRecoilValue, useRecoilState } from "recoil";
import { stockOutDocumentsFilterState } from "./recoil/selectors";
import { stockOutFilterAtom } from './recoil/atoms';
import { stockOutDocumentsSortingEnum } from './recoil/constants';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';


const { Search } = Input;


export default function LaptopStockOut() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { setPageTitle } = usePage();
    const [searchLoading, setSearchLoading] = React.useState(false);
    const { loading, setLoading, key } = useLoaderContext();
    const data = useRecoilValue(stockOutDocumentsFilterState);
    const [stockOutFilter, setStockOutFilter] = useRecoilState(stockOutFilterAtom);
    const [search, setSearch] = React.useState('');
    const [gridApi, setGridApi] = React.useState({});
    const [columnApi, setColumnApi] = React.useState({});

    
    const onError = error => {
        console.log("ERROR! ", error);
    }
    
    const onComplete = code => {
        console.log("COMPLETE! ", code);
    }

    UseScannerDetection({onError, onComplete});

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle("Wms - Stock Out");
    }, []);

    const onSearch = event => {
        console.log("search event", event);
        setSearch(event.target.value);
        if (!!gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.columnApi.autoSizeColumns();
      };


    const handleSortingChange = e => {
        if (!!e)
            setStockOutFilter({...stockOutFilter, state: stockOutDocumentsSortingEnum.BY_FINISHED });
        else
            setStockOutFilter({...stockOutFilter, state: stockOutDocumentsSortingEnum.BY_LABEL });
    }

 
    return (
        <div className="stock-out">
            <div className="input" style={{display: 'flex'}} >
                <Space direction="vertical">
                    <Search
                        placeholder="Document number"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onChange={onSearch}
                    />
                </Space>
                <Switch
                        defaultChecked={true}
                        checkedChildren="In progress"
                        unCheckedChildren="All"
                        onChange={handleSortingChange}
                        style={{ width: '100px', marginLeft: '30px', marginTop: '9px' }}
                    />

            </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                <div ref={wrapper}
                    className="ag-theme-balham"
                    style={{ width: '99%', marginTop: '30px', marginLeft: '10px'}}
                >
                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data}
                        defaultColDef={options.defaultColDef}
                        floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        rowClassRules={options.rowClassRules}
                        frameworkComponents={options.frameworkComponents}
                        onGridReady={onGridReady}
                    >
                    </AgGrid>
                </div>
            {/* </Loader> */}
        </div>
    )
}