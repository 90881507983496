import React, { useContext, useEffect, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageContext, {
  usePageContext,
} from "@bit/hlouzek.rhplus.page/dist/page/redux/PageContext";
import PageReducer from "@bit/hlouzek.rhplus.page/dist/page/redux/PageReducer";
import configureStore, { history } from "./store";
import App from "./containers/App";
import CacheBuster from "@bit/hlouzek.rhplus.cache-buster";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { ScannerContainer } from "@bit/hlouzek.rhplus.barcodescanner";
import { BreakpointsProvider } from "react-with-breakpoints";
import { LoaderContainer } from '@bit/hlouzek.rhplus.loader';

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey(
  "MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10"
);

// configuration Redux store
export const store = configureStore();

const breakpoints = {
  small: 468,
  medium: 768,
  large: 1024,
  xlarge: Infinity,
};

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);
  const pusherConfig = require(`${process.env.REACT_APP_PUSHER}`);

  return (
    <CacheBuster packageJson={import("../package.json")}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (

          <LoaderContainer config={pusherConfig} channel={"WMS_LOADING_CHANNEL"}>
            <ScannerContainer config={{ minLength: 5, beep: true }}>
              <PageContext.Provider value={{ pageState, pageDispatch }}>
                <BreakpointsProvider breakpoints={breakpoints}>
                  <App {...props} />
                </BreakpointsProvider>
              </PageContext.Provider>
            </ScannerContainer>
          </LoaderContainer>
        );
      }}
    </CacheBuster>
  );
};

export default function MainApp() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Switch>
          <Route path="/" component={Apps} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
