import * as React from "react";
import BarcodeInput from "@bit/hlouzek.rhplus.barcode-input";
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { Modal, Affix } from 'antd';
import BoxDetail from './components/MobileBoxDetail';
import LoadingOverlay from 'components/LoadingOverlay';
import NavBar from 'components/NavBar';


const { confirm } = Modal;

export default function MobileDeficitExcessDetail() {
    const { setPageTitle } = usePage();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { boxInfo } = apis;
    const [data, setData] = React.useState([]);
    const [visibility, setVisibility] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [top, setTop] = React.useState(90);




    React.useEffect(() => {
        setPageTitle("Box Inventory")
    }, []);

    const onComplete = (e) => {
        console.log("success", e);
        loadData(e);
    };

    const onError = (e) => {
        console.log("error", e);
    };


    const loadData = async ean => {
        setIsActive(true);
        try {
            const response = await callApi({ ...boxInfo, paramObject: { ean: ean } })
            const result = response.data;
            console.log("result", response);
            setData(result)
            setIsActive(false);
            setVisibility(true);

        }
        catch (err) {
            loadingError(ean);
            setIsActive(false);
        }
    }

    const loadingError = ean => {
        Modal.error({
            title: 'Error',
            content: `Cannot find ean ${ean}`,
        });
    }





    return (
        <LoadingOverlay
            active={isActive}
        >

            <div className="mobile-box-inventory">
                <Affix offsetTop={top}>
                    <div style={{ backgroundColor: '#fff' }}>
                        <h3>Scan barcode</h3>
                        <BarcodeInput
                            onSearch={onComplete}
                            onError={onError}
                            searchProperties={{
                                style: {
                                    width: 280
                                }
                            }}
                            showSearchButton={true}
                            showBarcodeButton={true}
                            hideCameraAfterDetection={true}
                            cameraPosition="down"
                            cameraClassName="mt-3"
                            className="barcode-input"
                            barcodeIconStyle="barcode-icon"
                        />
                    </div>
                </Affix>
                <div style={{minHeight: '500px'}}>
                    <BoxDetail visibility={visibility} data={data} style={{ marginTop: '50px', textAlign: 'left' }} />
                </div>
                <NavBar />
            </div>
        </LoadingOverlay>
    )
}