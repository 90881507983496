import * as React from 'react';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import LoadingOverlay from 'components/LoadingOverlay';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import { Modal, notification, InputNumber } from 'antd';



export default function LaptopRegrouping() {
    const { setPageTitle } = usePage();
    const [isActive, setIsActive] = React.useState(false);
    const [showSourceBox, setShowSourceBox] = React.useState(false);
    const [showDestinationBox, setShowDestinationBox] = React.useState(false);
    const [sourceBoxData, setSourceBoxData] = React.useState({});
    const [destinationBoxData, setDestinationBoxData] = React.useState({});
    const [enteredQuantity, setEnteredQuantity] = React.useState();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { getBoxInfo, regroupingBox } = apis;
    const { success, error } = useBeeper();


    React.useEffect(() => {
        setPageTitle("Wms - Regrouping")
    }, []);

    React.useEffect(() => {
        setEnteredQuantity(sourceBoxData.quantity);
    }, [, sourceBoxData]);

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const onComplete = code => {
        console.log("COMPLETE! ", code);
        setIsActive(true);
        loadData(code);
    }

    const loadData = async (code) => {
        console.log("I am working");
        const eanType = String(code).substr(0, 2);
        console.log("eanType", eanType);
        if (eanType === "27") {
            try {
                const response = await callApi({ ...getBoxInfo, paramObject: { Ean: code } })
                console.log("relocate response", response.data);
                if (response.data.active === false) {
                    if (!showSourceBox) {
                        Modal.error({
                            title: 'Error',
                            content: 'Source box is disabled',
                        });
                        setIsActive(false);
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Destination box is disabled',
                        });
                        setIsActive(false);
                    }
                }

                if (!showSourceBox) {
                    if (response.data.position === null) {
                        Modal.error({
                            title: 'Error',
                            content: 'Box is not at position',
                        });
                        setIsActive(false);
                    } else if (response.data.quantity <= 0) {
                        Modal.error({
                            title: 'Error',
                            content: 'Source box is empty',
                        });
                        setIsActive(false);
                    } else {
                        setSourceBoxData(response.data);
                        setShowSourceBox(true);
                        setIsActive(false);
                        success()
                    }
                } else if (showSourceBox) {
                    if (response.data.position === null) {
                        Modal.error({
                            title: 'Error',
                            content: 'Destination box is not at position',
                        });
                        setIsActive(false);
                        setShowSourceBox(false);
                    } else {
                        setDestinationBoxData(response.data);
                        setShowDestinationBox(true);
                        setIsActive(false);
                        success()
                    }

                    if (response.data.product.id != sourceBoxData.product.id) {
                        Modal.error({
                            title: 'Error',
                            content: 'Cannot regrouping to the box with different product',
                        });
                        setIsActive(false);
                        setShowDestinationBox(false);
                        setShowSourceBox(false);
                    } else if (response.data.ean === sourceBoxData.ean) {
                        Modal.error({
                            title: 'Error',
                            content: 'Cannot regroupig to the same box',
                        });
                        setIsActive(false);
                        setShowDestinationBox(false);
                        setShowSourceBox(false);
                    } else {
                        setDestinationBoxData(response.data);
                        setShowDestinationBox(true);
                        setIsActive(false);
                        success()
                    }
                }
            } catch (err) {
                error()
                errorMessage(err);
                setIsActive(false);
                setShowSourceBox(false);
            }
        } else if (eanType === "26") {
            const sourceBoxEan = sourceBoxData.ean;
            const destinationBoxEan = destinationBoxData.ean;
            const quantity = enteredQuantity;

            if (sourceBoxEan != "" && destinationBoxEan != "") {
                if (code != destinationBoxData.position.ean) {
                    Modal.error({
                        title: 'Error',
                        content: 'Wrong destination position',
                    });
                    setIsActive(false);
                    setShowDestinationBox(false);
                    setShowSourceBox(false);
                } else {
                    try {
                        console.log("I am regrouping to position", code);
                        const positionResponse = await callApi({ ...regroupingBox, paramObject: { SourceBoxEan: sourceBoxEan, DestinationBoxEan: destinationBoxEan, Quantity: quantity } })
                        if (positionResponse.status === 200) {
                            Modal.success({ content: 'Box has been successfully regrouping!'});
                            setShowSourceBox(false);
                            setShowDestinationBox(false);
                            setIsActive(false)
                            success()
                        } else {
                            Modal.error({ content: 'Error' });
                            error();
                            setIsActive(false);
                            setShowDestinationBox(false);
                            setShowSourceBox(false);
                        }
                    } catch (err) {
                        error()
                        errorMessage(err);
                        setShowSourceBox(false);
                        setShowDestinationBox(false);
                        setIsActive(false);
                    }
                }
            }
        }
    }

    const errorMessage = err => {
        Modal.error({ content: err });
    }

    const quantityChange = (value) => {
        setEnteredQuantity(value)
    }

    UseScannerDetection({ onError, onComplete });


    return (
        <div>
            <LoadingOverlay active={isActive} >
                <div style={{ minHeight: '600px' }}>
                    {showSourceBox ? (
                        <div style={{ width: '50%', justifyContent: 'center', margin: 'auto', marginTop: '30px' }}>
                            <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center' }}>
                                <h4>Enter quantity and scan destination box</h4>
                            </div>
                            <div style={{ marginTop: '30px', backgroundColor: '#f1f3f4', padding: '20px', boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.15)' }}>
                                <span>Source box</span>
                                <h3>{sourceBoxData.product.code}</h3>
                                <span>{sourceBoxData.product.description}</span>
                                <div style={{ width: '100%', margin: 'auto', marginTop: '30px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
                                        <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                            <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                            <span>{sourceBoxData.quantity}</span>
                                        </div>
                                        <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                            <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                            <span>{sourceBoxData.position.label}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '30px', lineHeight: '35px', verticalAlign: 'middle' }}>
                                        <span style={{marginRight: '10px'}}>Enter quantity</span>
                                        <InputNumber defaultValue={enteredQuantity} onChange={quantityChange} style={{ width: '135px', marginRight: '10px' }} />
                                        <span>{`<= ${sourceBoxData.quantity}`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center', marginTop: '30px' }}>
                            <h4>Scan source box</h4>
                            <img src={process.env.PUBLIC_URL + '/images/icons/barcode-icon.png'} style={{ width: '15%' }} />
                        </div>
                    )}
                    {showDestinationBox ? (
                        <div style={{ width: '50%', justifyContent: 'center', margin: 'auto', marginTop: '30px' }}>
                            <div style={{ marginTop: '30px', backgroundColor: '#f1f3f4', padding: '20px', boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.15)' }}>
                                <span>Destination box</span>
                                <h3 style={{ marginTop: '30px' }}>{destinationBoxData.product.code}</h3>
                                <span>{destinationBoxData.product.description}</span>
                                <div style={{ width: '100%', margin: 'auto', marginTop: '30px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
                                        <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                            <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                            <span>{destinationBoxData.quantity}</span>
                                        </div>
                                        <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                            <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                            <span>{destinationBoxData.position.label}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div />)}
                </div>

            </LoadingOverlay>
        </div>
    )
}