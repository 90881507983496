
const options = {
    columnDefs: [
            {
                headerName: "Client",
                field: "client",
                width: 100,
                filter: "agTextColumnFilter",   
            },
            {
                headerName: "Project name",
                field: "projectName",
                width: 150,
                filter: "agTextColumnFilter",
            },
            {
                headerName: "Address",
                field: "address",
                width: 530,
                filter: "agTextColumnFilter",
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
};


export default options;