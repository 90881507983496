import * as React from 'react';

export default class LogoRenderer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      url: (`https://rhplus.blob.core.windows.net/suppliers/${this.props.value}.png`)
    }
  }

  componentWillMount() {
    const {value} = this.props;
    if (value === null || value === '?')
      return <span />;
  }

  onError = () => {
      this.setState({
        url: '/images/suppliers/NA.png'
      });
  }

  render() {
    const {value} = this.props;

    return (
      <span>
        {value == '' ? (<span />) : (
        <img 
          src={this.state.url} 
          onError={this.onError}
          style={{maxHeight: 20, maxWidth: 50}} 
        />
        )}
      </span>
    )
  }
}