import moment from 'moment';

const options = {
    columnDefs: [
        {
            headerName: "Document number",
            field: "documentNumber",
            width: 200,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: "Company",
            field: "company",
            width: 150,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 150,
        },
        {
            headerName: "Created date",
            field: "createdDate",
            width: 200,
            autoHeight: true,
            wrapText: true,
            cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY HH:mm"),
        },
        {
            headerName: "Note",
            field: "note",
            width: 150,
        },
        {
            headerName: "Flow quantity",
            field: "flowQuantity",
            width: 150,
        }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.quantity > 0 },
        'rag-red-outer': function(params) {return params.data.quantity < 0}
    }
};





export default options;
