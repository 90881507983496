import * as React from 'react';
import Chart, {
    ValueAxis,
    ArgumentAxis,
    CommonPaneSettings,
    Grid,
    Series,
    Label,
    Legend,
    Size,
    Border,
    Tooltip,
    Export,
    LoadingIndicator
} from 'devextreme-react/chart';
import Enumerable from "linq";
import moment from 'moment';
import { Switch } from 'antd';


// const { Option } = Select;

export default function useChartBoxMovement({ data }) {
    const [chartData, setChartData] = React.useState([]);

    React.useEffect(() => {
        setChartData(data.slice(-30))
      }, [, data]);


    const handleChange = checked => {
        if (checked === true) {
            return setChartData(data.slice(-30));
        } else {
            return setChartData(data);
        }
    }

    const customizeTooltip = (arg) => {
        return {
            html: `<p style="color: #1db2f5; font-weight: bold;">${arg.argumentText}</p>
                    <div style="display: flex;">
                    <p style="font-size: 10px; margin-right: 5px">Box movements:</p>
                    <p style="color: #1db2f5;">${arg.valueText}</p>
                    </div>`,
            borderColor: "#1db2f5"
        }
    }


    return (
        <div id="chart-demo" style={{ width: '100%', justifyContent: 'center', margin: 'auto', marginTop: '20px' }}>
            <Switch checkedChildren="last 30" unCheckedChildren="all" defaultChecked  onChange={handleChange} style={{marginBottom: '20px'}} />
            <Chart
                title=""
                dataSource={chartData}>
                <Size height={220} />
                <ValueAxis
                    grid={{ opacity: 0.2 }}
                    valueType="numeric"
                    visible={false}
                >
                    <Label visible={true} />
                </ValueAxis>
                <ArgumentAxis type="discrete" visible={false}>
                    <Grid visible={true} opacity={0.2} />
                    <Label visible={true} />
                </ArgumentAxis>
                <CommonPaneSettings>
                    {/* <Border
                        visible={false}
                        width={2}
                        top={false}
                        right={false}
                    /> */}
                </CommonPaneSettings>
                <Series
                    argumentField="legends"
                    valueField="values"
                    type="stepline"
                />
                <Legend visible={false} />
                {/* <Export enabled={true} /> */}
                <Tooltip
                    enabled={true}
                    customizeTooltip={customizeTooltip} />
                <LoadingIndicator enabled={true} />
            </Chart>

            {/* <div className="action">
                <SelectBox
                    id="selectbox"
                    width={150}
                    valueExpr="id"
                    displayExpr="name"
                    items={months}
                    defaultValue={1}
                    onValueChanged={this.onValueChanged} />
                <div className="label">Choose a month:
                </div>
            </div> */}
        </div>
    )
}