import { selector } from 'recoil';
import { FetchDocuments } from './data';
import { deliveryFilterAtom } from './atoms';


export const deliveryDocumentsSelector = selector({
  key: "deliveryDocumentsSelector",
  get: async () => {
    const response = (await FetchDocuments())
    .data.projects;
    return response;
  },
  dangerouslyAllowMutability: true
})


export const deliveryDocumentsFilterState = selector({
  key: "deliveryDocumentsFilterState",
  get: ({get}) => {
    let items = get(deliveryDocumentsSelector);
    const {projectName, address} = get(deliveryFilterAtom);
    console.log("delivery items", items);

    if (!!projectName) {
      items =  items.filter(f => f.projectName === projectName);
        return items;
    } else if (!!address) {
        items = items.filter(f => (f.address || '').toLowerCase().includes(address.toLowerCase()));
        return items;
    } else {
        return items;
    }
  },
  dangerouslyAllowMutability: true
})
