import * as React from 'react';
import IconTale from './IconTale';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import {
    ProductIcon,
    DeliveryScheduleIcon,
    InfoIcon,
    StockInIcon,
    StockOutIcon,
    RegroupingIcon,
    SplitIcon,
    RelocateIcon,
    InventoryDeficitIcon,
    InventoryPositionsIcon
} from '@bit/hlouzek.rhplus.icons/dist/Icons/RhPlus';

export default function MobileWms() {
    const { setPageTitle } = usePage();

    React.useEffect(() => {
        setPageTitle("Strandhill - WMS")
    }, []);


    return (
        <div className="mobile-wms mt-5">
            <div>
                <ul>
                    <li>
                        <IconTale url="wms/info" title="INFO" subtitle="Ean information">
                            <InfoIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/stock-in" title="STOCK-IN" subtitle="Storage">
                            <StockInIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/stock-out" title="STOCK-OUT" subtitle="Picking up">
                            <StockOutIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/regrouping" title="REGROUPING" subtitle="Box moving">
                            <RegroupingIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/split" title="SPLIT" subtitle="Box splitting">
                            <SplitIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/relocate" title="RELOCATE" subtitle="Box moving">
                            <RelocateIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/deficit-excess" title="DEFICIT/EXCESS" subtitle="Box Inventory">
                            <InventoryDeficitIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/positions" title="POSITIONS" subtitle="Box Inventory">
                            <InventoryPositionsIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/delivery" title="DELIVERY" subtitle="Stock outs">
                            <DeliveryScheduleIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                    <li>
                        <IconTale url="wms/product" title="PRODUCT" subtitle="Spec. inventory">
                            <ProductIcon className="rhplus-3x" />
                        </IconTale>
                    </li>

                </ul>
            </div>
        </div>
    )
}