import * as React from "react";
import LaptopWms from './LaptopVersion';
import MobileWms from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';

export default function Info() {
  return (
    <div>
      <ShowAt breakpoint="xlarge" >
        <LaptopWms />
      </ShowAt>
      <ShowAt breakpoint="large">
        <LaptopWms />
      </ShowAt>
      <ShowAt breakpoint="medium">
        <LaptopWms />
      </ShowAt>
      <ShowAt breakpoint="small">
        <MobileWms />
      </ShowAt>
    </div>
  );
}