import * as React from 'react';
import { Input, Space, Affix } from 'antd';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import NavBar from 'components/NavBar';
import { useRecoilValue, useRecoilState } from "recoil";
import { deliveryDocumentsFilterState } from "./recoil/selectors";
import { deliveryFilterAtom } from './recoil/atoms';






const { Search } = Input;


export default function MobileDelivery() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const [top, setTop] = React.useState(130);
    const data = useRecoilValue(deliveryDocumentsFilterState);
    const [deliveryFilter, setDeliveryFilter] = useRecoilState(deliveryFilterAtom);




    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])


    React.useEffect(() => {
        setPageTitle("Delivery")
    }, []);



    const onCellClicked = e => {
        const { id } = e.data;
        window.location = `/app/wms/delivery/${id}`;
      }

    const onProjectNameSearch = value => {
        setDeliveryFilter({...deliveryFilter, projectName: value});
    }

    const onAddressSearch = value => {
        setDeliveryFilter({...deliveryFilter, address: value});
    }

    return (
        <div className="mobile-delivery">
            <Affix offsetTop={top}>
                <div className="input">
                    <Space direction="vertical" style={{ marginBottom: '10px' }}>
                        <Search
                            placeholder="Project name"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onProjectNameSearch}
                            className="delivery-input"
                        />
                    </Space>
                    <Space direction="vertical">
                        <Search
                            placeholder="Address"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onAddressSearch}
                            className="delivery-input"
                        />
                    </Space>

                </div>
            {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                <div ref={wrapper}
                    className="ag-theme-material"
                    style={{ width: '90%', justifyContent: 'center', margin: 'auto', marginTop: '30px', marginLeft: '20px', marginRight: '20px' }}
                >
                    <AgGrid
                        columnDefs={options.columnDefs}
                        rowData={data}
                        defaultColDef={options.defaultColDef}
                        // floatingFilter={true}
                        autoGroupColumnDef={options.autoGroupColumnDef}
                        //   sideBar={options.sideBar}
                        context={{
                            componentParent: this
                        }}
                        rowClassRules={options.rowClassRules}
                        onCellClicked={onCellClicked}
                        gridOptions={options.gridOptions}

                    >
                    </AgGrid>
                </div>
            {/* </Loader> */}
            </Affix>
            <NavBar />
        </div>
    )
}