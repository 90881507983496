import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
// import Footer from 'components/Footer';
// import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from '@bit/hlouzek.rhplus.top-nav';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import {ToastContainer} from 'react-toastify';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';



import Wms from './routes/Wms/routes/Root';
import DeficitExcess from './routes/Wms/routes/DeficitExcess';
import Delivery from './routes/Wms/routes/Delivery/routes/Root';
import DeliveryDetail from './routes/Wms/routes/Delivery/routes/Detail';
import CreateDelivery from './routes/Wms/routes/Delivery/routes/CreateDeliveryPage';
import CloseDelivery from './routes/Wms/routes/Delivery/routes/CloseDeliveryPage';
import Info from './routes/Wms/routes/Info';
import Positions from './routes/Wms/routes/Positions';
import Product from './routes/Wms/routes/Product';
import Regrouping from './routes/Wms/routes/Regrouping';
import Relocate from './routes/Wms/routes/Relocate';
import Split from './routes/Wms/routes/Split';
import StockIn from './routes/Wms/routes/StockIn/routes/Root';
import StockInDetail from './routes/Wms/routes/StockIn/routes/Detail';
import StockOut from './routes/Wms/routes/StockOut/routes/Root';
import StockOutDetail from './routes/Wms/routes/StockOut/routes/Detail';




class App extends React.Component {
  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }
    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <ToastContainer draggable />
              <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/wms`} />
                <Route path={`${match.url}/wms`} component={asyncComponent(() => import('./routes/Wms'))} />
                <CacheRoute exact path={`${match.url}/wms`} component={Wms} />
                <CacheRoute path={`${match.url}/wms/deficit-excess`} component={DeficitExcess} />
                <CacheRoute exact path={`${match.url}/delivery`} component={Delivery} />
                <CacheRoute exact path={`${match.url}/delivery/:deliveryCode`} component={DeliveryDetail} />
                <CacheRoute path={`${match.url}/delivery/create-delivery/:projectId`} component={CreateDelivery} />
                <CacheRoute path={`${match.url}/delivery/close-delivery/:projectId`} component={CloseDelivery} />
                <CacheRoute path={`${match.url}/wms/info`} component={Info} />
                <CacheRoute path={`${match.url}/wms/positions`} component={Positions} />
                <CacheRoute path={`${match.url}/wms/product`} component={Product} />
                <CacheRoute path={`${match.url}/wms/regrouping`} component={Regrouping} />
                <CacheRoute path={`${match.url}/wms/relocate`} component={Relocate} />
                <CacheRoute path={`${match.url}/wms/split`} component={Split} />
                <CacheRoute exact path={`${match.url}/stock-in`} component={StockIn} />
                <CacheRoute path={`${match.url}/stock-in/:stockInCode`} component={StockInDetail} />
                <CacheRoute exact path={`${match.url}/stock-out`} component={StockOut} />
                <CacheRoute path={`${match.url}/stock-out/:stockOutCode`} component={StockOutDetail} />
              </Switch>
            </div>
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App)); 