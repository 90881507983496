import * as React from 'react';
import { Affix, Collapse, Row, Col } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import './affix.css';

const { Panel } = Collapse;

export default function NavBar(props) {
    const [bottom, setBottom] = React.useState(0);


    const text = (
        <div style={{zIndex:100000}}>
            <Row style={{zIndex:100000}} style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
                <Col span={3}>
                    <a style={{zIndex:100000, textAlign: 'center'}}href="https://wms.rhplus.cloud/app/wms/info" target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/data06.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Info</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/stock-in" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/cloud10.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Stock in</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/stock-out" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/stock-out.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Stock out</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/regrouping" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-other/blockchain.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px', marginLeft: '-4px' }}>Regrouping</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/relocate" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/deploy08.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Relocate</p>
                    </a>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/split" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-docs/arc.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Split</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/deficit-excess" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-other/jobs.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px', marginLeft: '-6px' }}>Deficit/Excess</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/positions" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/globe08.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Positions</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/delivery" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/vehicle06.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Delivery</p>
                    </a>
                </Col>
                <Col span={3}>
                    <a href="https://wms.rhplus.cloud/app/wms/product" style={{textAlign: 'center'}} target="_blank">
                        <img src="https://rhplus.blob.core.windows.net/svg-azure-patterns/geometry01.svg" style={{ width: '90%' }} />
                        <p style={{ fontSize: '10px' }}>Product</p>
                    </a>
                </Col>
            </Row>

        </div>
    );



    return (
            <Affix style={{zIndex:100000}} offsetBottom={bottom}>
                <Collapse style={{zIndex:100000}} bordered={false} expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}>
                    <Panel style={{zIndex:100000}} header="" key="1">
                        {text}
                    </Panel>
                </Collapse>
            </Affix>
    )
}