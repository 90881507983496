import * as React from 'react';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { stockOutDocumentDetailState, stockOutPickPositionState, refreshState} from './recoil/atoms';
import { stockOutDocumentDetailSelector } from "./recoil/selectors/stockOutDocumentDetail";
// import {stockOutPickPositionSelector} from './recoil/selectors/stockOutPickPositionSelector';
import Enumerable from 'linq';
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import LoadingOverlay from 'components/LoadingOverlay';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import InfoModal from './components/InfoModal';
import ProductInfoContent from './components/ProductInfoContent';
import DocumentDetailPickBoxModal from './components/DocumentDetailPickBoxModal';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import StockoutQuantityRenderer from 'components/Renderers/StockoutQuantityRenderer';


export default function LaptopDetail() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { productPlacementInfo, stockOutBoxInfo, stockOutFromPosition } = apis;
    const [modalVisible, setModalVisible] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [clickData, setClickData] = React.useState({ productId: "", product: "", description: "", inStock: "" });
    const [isActive, setIsActive] = React.useState(false);
    const [pickBoxModalVisible, setPickBoxModalVisible] = React.useState(false);
    const [boxModalShow, setBoxModalShow] = React.useState(false);
    const [infoShow, setInfoShow] = React.useState(false);
    const [productId, setProductId] = React.useState(0);
    const [product, setProduct] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [createBoxModalShow, setCreateBoxModalShow] = React.useState(false);
    const [box, setBox] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [inventoryBox, setInventoryBox] = React.useState("");
    const [documentItemId, setDocumentItemId] = React.useState(0);
    const [stockOutPickPosition, setStockOutPickPosition] = useRecoilState(stockOutPickPositionState);
    const {success, error} = useBeeper();
    const refModal = React.useRef();
    const [gridData, setGridData] = useRecoilState(stockOutDocumentDetailSelector);

    

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle(`Wms - Stock Out ${gridData.documentCode}`)
    }, []);

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const onComplete = e => {
        console.log("pipnuto", e);
        if (infoShow) handleInventoryBox(e);
        else if (!boxModalShow) handlePickBox(e);
        else {
            handlePickPosition(e);
        }
    }

    const handleInventoryBox = e => {
        setInventoryBox(e);
    }

    const handlePickBox = async e => {
        setLoading(true);
        try {
            console.log("nacitam");
            const response = await callApi({ ...stockOutBoxInfo, paramObject: { ean: e, document: gridData.documentCode } })
            console.log("BoxData", response);
            setBox(response.data);
            setBoxModalShow(true);
        } catch (err) {
            setLoading(false)
        }
    };
 

    const refreshPage = () => {
        window.location.reload()
    }

    const submitPickPosition = async (values, selectedId, barcode) => {
        
        console.log("box", box)
        const { id, clientProductId, boxId, items } = box;
        const { quantity, splitItemChecked } = values;
        console.log("id", id);
        console.log("selectedId", selectedId);
        if (items.length > 1 && selectedId === null) return;

        setLoading(true);
        try {
            const response = await callApi({ ...stockOutFromPosition, paramObject: {
                documentItemId: selectedId || id,
                clientProductId: clientProductId,
                boxId: boxId,
                positionEAN: barcode,
                quantity: quantity,
                splitItem: splitItemChecked,
            }})

            await success(); 
            setBoxModalShow(false)
            refreshPage()
            if (splitItemChecked) {
                refreshPage()
            } else {
                setStockOutPickPosition(
                    {
                        id: response.data.documentItemId,
                        stockOut: response.data.stockOut,
                        palette: response.data.pallet,
                        remainStockOut: response.data.remainStockOut,
                    }
                )
            }
        } catch (err) {
            await error();
          };

    }

    const handlePickPosition = e => {
        refModal.current.submit(e);
    }

    const handleBoxModalHide = () => {
        setBoxModalShow(false);
      };

    UseScannerDetection({ onError, onComplete });

    const { documentItems: { documentItems } = {} } = gridData;


    const inProgressFirst = Enumerable.from(documentItems)
        .where(w => w.stockOut > 0 && w.stockOut < w.ordered ? 1 : 2)
        .where(w => w.stockOut === 0 ? 2 : 3)
        .where(w => w.ordered - w.stockOut === 0 ? 3 : 4)
        .orderBy(o => o.stockOut)
        .toArray()


    const onCellClicked = async e => {
        console.log("eee", e);
        const colId = e.column.colDef.field;
        const { product, description, inStock } = e.data;
        if (colId === "positions") {
            setIsActive(true);
            const productId = e.data.productId;
            try {
                const response = await callApi({ ...productPlacementInfo, paramObject: { productId: productId } })
                const result = response.data.boxes;
                console.log("cell clicked data", result);
                setModalData(result);
                setInfoShow(true);
                setClickData({ productId: productId, product: product, description: description, inStock: inStock });
                setIsActive(false)
            }
            catch (err) {
                console.log("error", err);
                setIsActive(false);
            }

        }
  
    }

    console.log("ref", refModal);
    return (
        <LoadingOverlay
            active={isActive}
        >
            <div className="stock-out-detail">
                <InfoModal
                    title={clickData.product}
                    show={infoShow}
                    close={() => setInfoShow(false)}
                    >
                    <ProductInfoContent
                        productId={clickData.productId}
                        product={clickData.product}
                        description={clickData.description}
                        inventoryBox={inventoryBox}
                        documentItemId={documentItemId}
                        disableInventoryBox={() => handleInventoryBox('')}
                    />
                </InfoModal>
                <DocumentDetailPickBoxModal
                    ref={refModal}
                    show={boxModalShow}
                    onHide={handleBoxModalHide}
                    submit={submitPickPosition}
                    {...box}
                    // handleScanner={onComplete}
                />
                <div>
                    {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                        <div ref={wrapper}
                            className="ag-theme-balham"
                            style={{ width: '52%', margin: 'auto', marginTop: '10px' }}
                        >

                            <AgGrid
                                columnDefs={options.columnDefs}
                                rowData={inProgressFirst}
                                // defaultColDef={options.defaultColDef}
                                floatingFilter={true}
                                suppressRowTransform={true}
                                // autoGroupColumnDef={options.autoGroupColumnDef}
                                // getRowStyle={getRowStyle}
                                //   sideBar={options.sideBar}
                                // context={{
                                //     componentParent: this
                                // }}
                                onCellClicked={onCellClicked}
                                frameworkComponents={
                                    {
                                        stockoutQuantityRenderer: StockoutQuantityRenderer    
                                    }
                                }
                            >
                            </AgGrid>
                        </div>
                    {/* </Loader> */}
                </div>

            </div>
        </LoadingOverlay>
    )
}