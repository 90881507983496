import * as React from 'react';
import { Modal, Form, InputNumber, Button } from 'antd';
import Enumerable from "linq";
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import { StockInDocumentDetailSelector } from "../recoil/selectors";
import {useRecoilRefresher_UNSTABLE} from "recoil";


export default function CreateBoxModal({splitSubmit, submit, show, data=[], items, close}) {
    const [visible, setVisible] = React.useState(false);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [showSplitItemModal, setShowSplitItemModal] = React.useState(false);
    const [id, setId] = React.useState(0);
    const [split, setSplit] = React.useState(0);
    const [initialQuantity, setInitialQuantity] = React.useState(0);
    const [product, setProduct] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [dataDocumentItem, setDataDocumentItem] = React.useState({});
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { mergeStockInItems } = apis;
    const { success, error } = useBeeper();
    const [form] = Form.useForm();
    const RecoilRefresh = useRecoilRefresher_UNSTABLE(StockInDocumentDetailSelector);
    console.log("show modal", show);
    console.log("data here", data);


    React.useEffect(() => {
        if (show === true) {
            const {documentItem = {}} = data;
            const {id, product, ordered, deficit, surplus, demaged, position} = documentItem;
            form.setFieldsValue({
                    ordered: initialQuantity || ordered,
                    deficit: deficit,
                    surplus: surplus,
                    demaged: demaged
                });
            setDataDocumentItem(documentItem);
            setProduct(product);
            setPosition(position);
            setInitialQuantity(initialQuantity || ordered);
            setDisabled(false);
            setSplit("");
            setId(id);
        }
    })


    const itemMergeModal = (sum, count) => {
        Modal.confirm({
            title: `Do you want to merge ${count} items of product ${product} to sum ${sum}?`,
            onOk: handleMergeStockInItems
        })
    }

    const handleMergeStockInItems = async () => {
        console.log("merge working");
        // setIsActive(true);
        try {
            const response = await callApi({ ...mergeStockInItems, paramObject: { documentItemId: id } })
            console.log("merge result", response);
            success();
            RecoilRefresh();
            handleClose(true);
        }
        catch (err) {
            error()
            // setIsActive(false);
        }
    }

    const itemsMerge = (e) => {
        e.preventDefault();
        const { sum, count } = itemsMergeCalculate();
        itemMergeModal(sum, count);
    }

    const itemsMergeCalculate = () => {
        if (data.documentItem === undefined) return {};

        const { productId, product } = data.documentItem;

        if (items === undefined || items.length === 0) return {};

        const result = Enumerable.from(items)
            .where(
                (w) =>
                    w.productId === productId &&
                    w.boxId === null &&
                    w.state === "in progress"
            )
            .groupBy((g) => g.productId)
            .select((s) => ({
                sum: s.sum((e) => e.ordered),
                count: s.count((e) => e.ordered),
            }))
            .singleOrDefault();
        return result || {};
    };

    const handleOk = (e) => {
        console.log("I am working", e);
        e.preventDefault();
        const { ordered, deficit, surplus, demaged } = form.getFieldsValue(); 
        submit({
          ...data.documentItem,
          delivered: delivered(),
          quantity: ordered,
          deficit,
          surplus,
          demaged,
          refresh: delivered != ordered,
        });
        handleClose();
    };

    const handleClose = (reload = false) => {
        setInitialQuantity(0);
        form.resetFields();        
        setIsModalVisible(false);
        close(reload)
    };

    const delivered = () => {
        const {ordered, deficit, surplus} = form.getFieldsValue();
        return (
            (parseInt(ordered) || 0) -
            (parseInt(deficit) || 0) +
            (parseInt(surplus) || 0)
        );
    }


    const { sum, count } = itemsMergeCalculate();
    const { ordered } = dataDocumentItem;
        return (
                <Modal 
                title="Create Box" 
                className="create-box-modal"
                visible={show} 
                okText="Create Box" 
                width={300} 
                onOk={handleOk} 
                onCancel={handleClose} 
                okButtonProps={{ disabled: disabled || initialQuantity < ordered }}
                >
                    <div style={{backgroundColor: '#faee8e', marginBottom: '20px'}}><b>Current position:</b> {position}</div>
                    <Form
                        form={form}         
                    >
                        <table style={{width: '90%', margin: 'auto', fontSize: '12px'}}>
                            <tbody>
                                <tr style={{justifyContent: 'space-between', marginTop: '20px'}}>
                                    <th>Product</th>
                                    <td>
                                        <b>{product}</b>
                                    </td>
                                </tr>
                                <tr style={{justifyContent: 'space-between'}}>
                                    <th>Ordered</th>
                                    <td>
                                        <Form.Item name="ordered" style={{height: '15px'}}>
                                              <InputNumber className="modal-input" />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr style={{justifyContent: 'space-between'}}>
                                    <th>Delivered</th>
                                    <td>{delivered()}</td>
                                </tr>
                                <tr style={{justifyContent: 'space-between'}}>
                                    <th>Deficit</th>
                                    <td>
                                        <Form.Item name="deficit" style={{height: '15px'}}>
                                            <InputNumber className="modal-input" />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr style={{justifyContent: 'space-between'}}>
                                    <th>Surplus</th>
                                    <td>
                                        <Form.Item name="surplus" style={{height: '15px'}}>
                                            <InputNumber className="modal-input"/>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr style={{justifyContent: 'space-between'}}>
                                    <th>Demaged</th>
                                    <td>
                                        <Form.Item name="demaged" style={{height: '15px'}}>
                                            <InputNumber className="modal-input" />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {count > 1 ? (
                            <Button
                                disabled={disabled}
                                className="btn btn-primary btn-block"
                                onClick={itemsMerge}
                            >
                                Merge {count} items to sum {sum}
                            </Button>
                        ) : (
                            <div />
                        )}
                    </Form>
                </Modal>
        )
}
