import * as React from 'react';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import Enumerable from 'linq';
import moment from 'moment';


export default function StockMovements({ visible, data=[] }) {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const {movements=[]} = data;
    console.log("movements data", data);




    const movementsData = Enumerable.from(movements)
    .orderByDescending(o => moment(o.createdDate))
    .toArray();



    if (visible === true) {
        return (
                <div className="mobile-info">
                    <span>Stock movements</span>
                    {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                        <div
                            className="ag-theme-material"
                        >

                            <AgGrid
                                columnDefs={options.columnDefs}
                                rowData={movementsData}
                                defaultColDef={options.defaultColDef}
                                // floatingFilter={true}
                                suppressRowTransform={true}
                                autoGroupColumnDef={options.autoGroupColumnDef}
                                // getRowStyle={getRowStyle}
                                //   sideBar={options.sideBar}
                                rowClassRules={options.rowClassRules}
                                domLayout = "autoHeight"
                                context={{
                                    componentParent: this
                                }}
                            >
                            </AgGrid>
                        </div>
                    {/* </Loader> */}
                </div>
        )

    } else {
        return (
            <div />
        )
    }
        
}