import * as React from 'react';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import LoadingOverlay from 'components/LoadingOverlay';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import { Modal, Form, InputNumber, Button, message } from 'antd';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';





export default function LaptopSplit() {
    const [isActive, setIsActive] = React.useState(false);
    const { setPageTitle } = usePage();
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { getBoxInfo, splitBox } = apis;
    const { success, error } = useBeeper();
    const [data, setData] = React.useState({});
    const [boxDetail, setBoxDetail] = React.useState(false);
    const [form] = Form.useForm();
    console.log("is active", isActive);

    React.useEffect(() => {
        setPageTitle("Wms - Split")
    }, []);

    const onComplete = (e) => {
        console.log("split ean", e);
        setIsActive(true)
        const eanType = String(e).substr(0, 2);
        if (eanType === "27") {
            loadBoxInfo(e);
        }
    };

    const loadBoxInfo = async e => {
        console.log("I am loading", e);
        try {
            const response = await callApi({ ...getBoxInfo, paramObject: { ean: e } })
            console.log("loading response", response);
            const result = response.data;
            if (!result.active) {
                setIsActive(false);
                error();
                boxDisabledError();
            } else {
                setIsActive(false);
                setData(result);
                setBoxDetail(true);
            }
        }
        catch (err) {
            error();
            setIsActive(false);
            errorModal(err);
        }

    }

    const handleSubmit = async (values) => {
        values.preventDefault();
        setIsActive(true);
        const { numberOfBoxes, quantityPerBox } = form.getFieldsValue();
        const { id, quantity } = data;
        try {
            const response = await callApi({
                ...splitBox, paramObject: {
                    boxId: id,
                    boxTotalQuantity: quantity,
                    BoxCount: numberOfBoxes,
                    boxQuantity: quantityPerBox
                }
            })
            success();
            setIsActive(false);
            setBoxDetail(false);
            setData({});
            form.resetFields();
            splitSuccess();
        }
        catch (err) {
            error();
            setIsActive(false);
            errorModal();
        }

    };

    const splitSuccess = () => {
        Modal.success({content: 'Box has been successfully splited!'});
    };

    const boxDisabledError = () => {
        Modal.error({
            title: 'Error',
            content: 'Box is disabled!',
        })
    }

    const errorModal = (err) => {
        Modal.error({
            title: 'Error',
            content: err,
        });
    }

    const onError = error => {
        console.log("ERROR! ", error);
    }

    UseScannerDetection({ onError, onComplete });
    const {
        quantity,
        product: { code, description } = {},
        position
    } = data;
    return (
        <LoadingOverlay active={isActive} >
            <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center', marginTop: '30px' }}>
                <h4>Scan Box</h4>
                <img src={process.env.PUBLIC_URL + '/images/icons/barcode-icon.png'} style={{ width: '15%' }} />
            </div>
            {boxDetail ? (
                <div style={{ width: '70%', margin: 'auto', marginTop: '50px', marginBottom: '50px', padding: '40px', backgroundColor: '#f9f9f9', boxShadow: '3px 5px 10px rgba(0, 0, 0, 0.3)' }}>
                    <div style={{width: '70%', margin: 'auto'}}>
                        <div>
                            <h5>{code}</h5>
                            <p>{description}</p>
                        </div>
                        <div style={{ width: '100%', margin: 'auto', marginTop: '30px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
                                <div style={{ display: 'flex', width: '50%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                    <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                    <span>{quantity}</span>
                                </div>
                                <div style={{ display: 'flex', width: '50%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                    <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                    <span>{!!position ? position.label : "Position is not assigned"}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '40%', justifyContent: 'center', margin: 'auto', marginTop: '30px' }}>
                            <Form
                                onFinish={handleSubmit}
                                form={form}
                            >
                                <Form.Item label="Number of boxes" name="numberOfBoxes" style={{ width: '100%' }}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item label="Quantity per box" name="quantityPerBox" style={{ width: '100%' }}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    Split
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>

            ) : (
                <div />
            )}
        </LoadingOverlay>
    )
}