import { selector } from 'recoil';
import { FetchDocuments } from '../data';
import { stockOutDocumentDetailState, refreshState } from '../atoms';


export const stockOutDocumentDetailSelector = selector({
    key: "stockOutDocumentDetailSelector",
    get: async ({ get }) => {
        const documentNumber = get(stockOutDocumentDetailState);
        const response = (await FetchDocuments(documentNumber))
            .data;
        return response;
    },
    dangerouslyAllowMutability: true
})

export default stockOutDocumentDetailSelector;