import * as React from "react";
import LaptopStockIn from "./laptopVersion";
import MobileStockIn from "./mobileVersion";
import { ShowAt, HideAt } from "react-with-breakpoints";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


export default function StockIn() {

  return (
    <div>
      <React.Suspense
        fallback={<Loader
          type="Oval"
          color="#46c78f"
          height={60}
          width={60}
          timeout={3000} //3 secs
          style={{ textAlign: 'center', margin: 'auto', marginTop: '70px' }}
        />}>
        <div>
          <ShowAt breakpoint="xlarge">
            <LaptopStockIn />
          </ShowAt>
          <ShowAt breakpoint="large">
            <LaptopStockIn />
          </ShowAt>
          <ShowAt breakpoint="medium">
            <MobileStockIn />
          </ShowAt>
          <ShowAt breakpoint="small">
            <MobileStockIn />
          </ShowAt>
        </div>
      </React.Suspense>

    </div>
  );
}
