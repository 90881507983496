import * as React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import usePage from '@bit/hlouzek.rhplus.page';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { useHistory } from "react-router-dom";

const { confirm } = Modal;


export default function CreateDeliveryPage({match}) {
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { createDelivery } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle } = usePage();
    const [isActive, setIsActive] = React.useState(false);
    let history = useHistory();
    

    React.useEffect(() => {
        setPageTitle("Wms - Create Delivery")
    }, []);

    const onFormFinish = (values) => {
        console.log("Success", values);
        const note = values.note;
        const {projectId} = match.params

        handleCreateDelivery(note, projectId);


    }

    const handleCreateDelivery = async (note, projectId) => {
        setIsActive(true);
        try {
            const response = await callApi({ ...createDelivery, paramObject: { note: note, projectId: projectId }})
            console.log("remove response", response);
            if (response.status === 200) {
                history.push({
                    pathname: `/app/wms/delivery/close-delivery/${projectId}`,
                    state: {
                      message: `Delivery '${response.data.delivery.label}' has been created and printed`
                    }
                  });
                createSuccess();
                setIsActive(false);
    
            } else {
                createError();
                setIsActive(false)
            }
    
         
        }
        catch (err) {
            setIsActive(false);
        }
    }

    const createSuccess = () => {
        Modal.success({
            content: 'Successfully created',
        });
    }
    
    
    const createError = () => {
        Modal.error({
            title: 'Created failed',
            content: 'Connection problem, please try again later.',
        });
    }


    return (
        <div className="create-delivery">
            <Form
                layout="vertical"
                onFinish={onFormFinish}
            >
                <Form.Item name ="note" label="Create new delivery" style={{width: '60%', margin: 'auto', textAlign: 'center'}}>
                    <Input placeholder="Enter a note" />
                </Form.Item>
                <Form.Item style={{textAlign: 'center', margin: 'auto', marginTop: '20px'}}>
                    <Button type="primary" htmlType="submit">Create</Button>
                </Form.Item>
            </Form>
        </div>
    )
}