import * as React from 'react';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import options from './MobileGridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import { Button, Affix, Switch, Input } from 'antd';
import Enumerable from 'linq';
import { Modal } from 'antd';
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import LoadingOverlay from 'components/LoadingOverlay';
import StockoutQuantityRenderer from 'components/Renderers/StockoutQuantityRenderer';
import NavBar from 'components/NavBar';
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { stockOutDocumentDetailState, stockOutPickPositionState, refreshState } from './recoil/atoms';
import { stockOutDocumentDetailSelector } from "./recoil/selectors/stockOutDocumentDetail";
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import InfoModal from './components/InfoModal';
import ProductInfoContent from './components/ProductInfoContent';
import DocumentDetailPickBoxModal from './components/DocumentDetailPickBoxModal';
import useBeeper from '@bit/hlouzek.rhplus.beeper';



export default function MobileDetail() {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const { setPageTitle, setPageSubtitle, changeLogoVisible, addIcon } = usePage();
    const [top, setTop] = React.useState(130);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { productPlacementInfo, stockOutBoxInfo, stockOutFromPosition } = apis;
    const [modalVisible, setModalVisible] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [clickData, setClickData] = React.useState({ productId: "", product: "", description: "", inStock: "" });
    const [isActive, setIsActive] = React.useState(false);
    const [pickBoxModalVisible, setPickBoxModalVisible] = React.useState(false);
    const [boxModalShow, setBoxModalShow] = React.useState(false);
    const [infoShow, setInfoShow] = React.useState(false);
    const [productId, setProductId] = React.useState(0);
    const [product, setProduct] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [createBoxModalShow, setCreateBoxModalShow] = React.useState(false);
    const [box, setBox] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [inventoryBox, setInventoryBox] = React.useState("");
    const [documentItemId, setDocumentItemId] = React.useState(0);
    const [stockOutPickPosition, setStockOutPickPosition] = useRecoilState(stockOutPickPositionState);
    const { success, error } = useBeeper();
    const refModal = React.useRef();
    const [gridData, setGridData] = useRecoilState(stockOutDocumentDetailSelector);
    const [filteredData, setFilteredData] = React.useState();
    const [search, setSearch] = React.useState('');
    const [gridApi, setGridApi] = React.useState({});
    const [columnApi, setColumnApi] = React.useState({});

    console.log("nacteno mobile");

    React.useLayoutEffect(() => {
        computeGridHeight(wrapper, 10);
    }, [, loading])

    React.useEffect(() => {
        setPageTitle(gridData.documentCode);
        // setPageSubtitle("Thomas-Mann-Platz 1b, 09130 Chemnitz, DE");
    }, [, gridData])

    React.useEffect(() => {
        setFilteredData(inProgress);
        // setPageSubtitle("Thomas-Mann-Platz 1b, 09130 Chemnitz, DE");
    }, [, gridData])

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const onComplete = e => {
        console.log("pipnuto", e);
        if (infoShow) handleInventoryBox(e);
        else if (!boxModalShow) handlePickBox(e);
        else {
            handlePickPosition(e);
        }
    }

    const handleInventoryBox = e => {
        setInventoryBox(e);
    }

    const handlePickBox = async e => {
        setLoading(true);
        try {
            console.log("nacitam");
            const response = await callApi({ ...stockOutBoxInfo, paramObject: { ean: e, document: gridData.documentCode } })
            console.log("BoxData", response);
            setBox(response.data);
            setBoxModalShow(true);
        } catch (err) {
            setLoading(false)
        }
    };


    const refreshPage = () => {
        window.location.reload()
    }

    const submitPickPosition = async (values, selectedId, barcode) => {

        console.log("box", box)
        const { id, clientProductId, boxId, items } = box;
        const { quantity, splitItemChecked } = values;
        console.log("id", id);
        console.log("selectedId", selectedId);
        if (items.length > 1 && selectedId === null) return;

        setLoading(true);
        try {
            const response = await callApi({
                ...stockOutFromPosition, paramObject: {
                    documentItemId: selectedId || id,
                    clientProductId: clientProductId,
                    boxId: boxId,
                    positionEAN: barcode,
                    quantity: quantity,
                    splitItem: splitItemChecked,
                }
            })

            await success();
            setBoxModalShow(false)
            refreshPage()
            if (splitItemChecked) {
                refreshPage()
            } else {
                setStockOutPickPosition(
                    {
                        id: response.data.documentItemId,
                        stockOut: response.data.stockOut,
                        palette: response.data.pallet,
                        remainStockOut: response.data.remainStockOut,
                    }
                )
            }
        } catch (err) {
            await error();
        };

    }

    const handlePickPosition = e => {
        refModal.current.submit(e);
    }

    const handleBoxModalHide = () => {
        setBoxModalShow(false);
    };

    UseScannerDetection({ onError, onComplete });

    const { documentItems: { documentItems } = {} } = gridData;


    const inProgress = Enumerable.from(documentItems)
        .where(w => w.stockOut < w.ordered)
        .toArray()

    // const done = Enumerable.from(documentItems)
    //     .where(w => w.stockOut === w.ordered)
    //     .toArray()


    const onCellClicked = async e => {
        console.log("eee", e);
        const colId = e.column.colDef.field;
        const { product, description, inStock } = e.data;
        if (colId === "positions") {
            setIsActive(true);
            const productId = e.data.productId;
            try {
                const response = await callApi({ ...productPlacementInfo, paramObject: { productId: productId } })
                const result = response.data.boxes;
                console.log("cell clicked data", result);
                setModalData(result);
                setInfoShow(true);
                setClickData({ productId: productId, product: product, description: description, inStock: inStock });
                setIsActive(false)
            }
            catch (err) {
                console.log("error", err);
                setIsActive(false);
            }

        }

    }

    const handleSortingChange = e => {
        if (!!e)
            setFilteredData(inProgress);
        else
            setFilteredData(documentItems);
    }


    const handleSearch = event => {
        console.log("search event", event.target.value);
        setSearch(event.target.value);
        if (!!gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.columnApi.autoSizeColumns();
      };
    


    return (
        <LoadingOverlay
            active={isActive}
        >
            <div className="mobile-stock-out">
                <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px'}}>
                <Input size="small" placeholder="Search" onChange={handleSearch} value={search || ''} style={{width: '40%', border: 'none', borderBottom: '0.1px solid #d9d9d9'}} />
                <Switch
                    defaultChecked={true}
                    checkedChildren="In progress"
                    unCheckedChildren="All"
                    onChange={handleSortingChange}
                    // style={{ width: '100px', margin: 'auto', marginTop: '15px' }}
                />
                </div>
               
                <InfoModal
                    title={clickData.product}
                    show={infoShow}
                    close={() => setInfoShow(false)}
                >
                    <ProductInfoContent
                        productId={clickData.productId}
                        product={clickData.product}
                        description={clickData.description}
                        inventoryBox={inventoryBox}
                        documentItemId={documentItemId}
                        disableInventoryBox={() => handleInventoryBox('')}
                    />
                </InfoModal>
                <DocumentDetailPickBoxModal
                    ref={refModal}
                    show={boxModalShow}
                    onHide={handleBoxModalHide}
                    submit={submitPickPosition}
                    {...box}
                // handleScanner={onComplete}
                />

                <div>
                    {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                    <div ref={wrapper}
                        className="ag-theme-material"
                        style={{ width: '90%', justifyContent: 'center', margin: 'auto', marginTop: '5px', marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}
                    >

                        <AgGrid
                            columnDefs={options.columnDefs}
                            rowData={filteredData}
                            defaultColDef={options.defaultColDef}
                            suppressRowTransform={true}
                            autoGroupColumnDef={options.autoGroupColumnDef}
                            // getRowStyle={getRowStyle}
                            //   sideBar={options.sideBar}
                            rowClassRules={options.rowClassRules}
                            context={{
                                componentParent: this
                            }}
                            onCellClicked={onCellClicked}
                            frameworkComponents={
                                {
                                    stockoutQuantityRenderer: StockoutQuantityRenderer
                                }
                            }
                            onGridReady={onGridReady}
                            gridOptions={options.gridOptions}

                        >
                        </AgGrid>
                    </div>
                    {/* </Loader> */}
                </div>
                <NavBar />
            </div>
        </LoadingOverlay >
    )
}