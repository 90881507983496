import * as React from 'react';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgGrid from '@bit/hlouzek.rhplus.ag-grid';
import AgGridHeight from '@bit/hlouzek.rhplus.ag-grid-height';
import 'ag-grid-enterprise';
import options from './GridOptions';
import Loader from '@bit/hlouzek.rhplus.loader';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import Enumerable from 'linq';
import moment from 'moment';


const gridData = [
    {
        documentNumber: "V00005361",
        company: "Zielona Gora",
        quantity: "-4",
        createdDate: "20.08.21 06:28:07",
        note: "",
        flowQuantity: "-4"
    }
]

export default function StockMovements({ visible, data=[] }) {
    let wrapper = React.useRef();
    const { computeGridHeight } = AgGridHeight();
    const { loading, setLoading, key } = useLoaderContext();
    const {movements=[]} = data;
    console.log("movements data", data);


    const movementsData = Enumerable.from(movements)
    .orderByDescending(o => moment(o.createdDate))
    .toArray();

    if (visible === true) {
        return (
            <CardBox styleName="mb-3" heading="Stock movements">
                <div>
                    {/* <Loader styles={{ height: 'calc(100vh - 100px)' }}> */}
                        <div
                            className="ag-theme-material"
                            style={{width: '90%', margin: 'auto'}}
                        >

                            <AgGrid
                                columnDefs={options.columnDefs}
                                rowData={movementsData}
                                defaultColDef={options.defaultColDef}
                                // floatingFilter={true}
                                suppressRowTransform={true}
                                autoGroupColumnDef={options.autoGroupColumnDef}
                                // getRowStyle={getRowStyle}
                                //   sideBar={options.sideBar}
                                rowClassRules={options.rowClassRules}
                                domLayout = "autoHeight"
                                context={{
                                    componentParent: this
                                }}
                            >
                            </AgGrid>
                        </div>
                    {/* </Loader> */}
                </div>
            </CardBox>
        )

    } else {
        return (
            <div />
        )
    }
        
}