import * as React from "react";
import LaptopSplit from './LaptopVersion';
import MobileSplit from './MobileVersion';
import { ShowAt, HideAt } from 'react-with-breakpoints';
import {favicons, useFavicon} from '@bit/hlouzek.rhplus.favicon';

function getTitleEl() {
  return document.getElementById("app-title");
}

export default function Info() {
  useFavicon({url: favicons.wms.split});

  const titleChange = () => {
    const title = getTitleEl();
    console.log("title", title);
    // favicon.href = `${process.env.PUBLIC_URL} + /favicons/stockInIcon.ico`;
    title.innerHTML = "Split"
  };

  React.useEffect(() => {
    titleChange();
}, []);

  return (
    <div>
      <ShowAt breakpoint="xlarge" >
        <LaptopSplit />
      </ShowAt>
      <ShowAt breakpoint="large">
        <LaptopSplit />
      </ShowAt>
      <ShowAt breakpoint="medium">
        <MobileSplit />
      </ShowAt>
      <ShowAt breakpoint="small">
        <MobileSplit />
      </ShowAt>
    </div>
  );
}