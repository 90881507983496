import * as React from 'react';
import { apis } from 'api/apiService';
import {callApi} from '@bit/hlouzek.rhplus.api';

export const FetchDocuments = async documentNumber => {
  const { stockInDocumentDetail } = apis;
  //const { callApi } =  useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);  
  const response = await callApi({ ...stockInDocumentDetail(documentNumber), url: `${process.env.REACT_APP_COMPANYPOINT_PROXY}${stockInDocumentDetail(documentNumber).url}`});
 console.log("document Detail response", response)
  return response;
}   