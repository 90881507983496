import * as React from 'react';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';

export const FetchDocuments = async () => {
  const { stockOutDocuments } = apis;
  const { callApi } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);  


  const response = await callApi({ ...stockOutDocuments});
  return response;
}