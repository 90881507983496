import moment from 'moment';
import LogoRenderer from 'components/Renderers/LogoRenderer';

const options = {
    columnDefs: [
            {
                headerName: "!",
                field: "mistakes",
                width: 40,
            },
            {
                headerName: "Date of loading",
                field: "dateOfLoading",
                filter: "agTextColumnFilter",
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY"),
                width: 70
            },
            {
                headerName: "Document number",
                field: "documentNumber",
                filter: "agTextColumnFilter", 
                width: 83,
                cellRenderer: params => {
                    const { documentNumber, company } = params.data;
                    const id = documentNumber
                    if (!!id) {
                      return `<a href='stock-out/${documentNumber}'>${documentNumber}</a>`
                    }
                  }
            },
            {
                headerName: "Picking up to",
                field: "pickingUpTo",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY"),
                width: 75,
            },
            {
                headerName: "State",
                field: "state",
                filter: "agTextColumnFilter", 
                width: 80,
            },
            {
                headerName: "Order name",
                field: "company",
                filter: "agTextColumnFilter", 
                width: 110,
            },
            {
                headerName: "Note",
                field: "note",
                filter: "agTextColumnFilter", 
                width: 180
            },
            {
                headerName: "Type of shop",
                field: "typeOfShop",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "Shop subtype",
                field: "shopSubtype",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "Department",
                field: "department",
                filter: "agTextColumnFilter", 
                width: 90
            },
            {
                headerName: "Subdepartment",
                field: "subdepartment",
                filter: "agTextColumnFilter", 
                width: 100
            },
            {
                headerName: "Created",
                field: "createDate",
                filter: "agTextColumnFilter",
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm"),
                width: 100
            },
            {
                headerName: "Created by",
                field: "createdBy",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "Changed",
                field: "changed",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm"),
                width: 100
            },
            {
                headerName: "Work order",
                field: "workOrder",
                filter: "agTextColumnFilter",
                width: 60
            },
            {
                headerName: "Sage state",
                field: "sageState",
                filter: "agTextColumnFilter",
                width: 50
            },
            {
                headerName: "Changed by",
                field: "changedBy",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "CDC",
                field: "cdc",
                filter: "agTextColumnFilter", 
                width: 50
            },
            {
                headerName: "Floor",
                field: "floor",
                filter: "agTextColumnFilter",
                width: 55
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.state === "OK"; },
    },
    frameworkComponents: {
        logoRenderer: LogoRenderer,
      }
      
};





export default options;