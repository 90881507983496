import moment from 'moment';
import LogoRenderer from 'components/Renderers/LogoRenderer';

const options = {
    columnDefs: [
            {
                headerName: "!",
                field: "mistakes",
                width: 40,
            },
            {
                headerName: "Document number",
                field: "documentNumber",
                filter: "agTextColumnFilter", 
                width: 83,
                cellRenderer: params => {
                    const { documentNumber } = params.data;
                    const id = documentNumber
                    if (!!id) {
                      return `<a href='stock-in/${documentNumber}'>${documentNumber}</a>`
                    }
                  }
                
            },
            {
                headerName: "Date of receipt",
                field: "dateOfReceipt",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY"),
                width: 75,
            },
            { 
                headerName: 'Logo', 
                field: 'logo', 
                width: 65, 
                cellRenderer: 'logoRenderer', 
                cellStyle: { textAlign: 'center' }, 
                suppressMenu: true
            },
            {
                headerName: "Company",
                field: "company",
                filter: "agTextColumnFilter", 
                width: 150,
            },
            {
                headerName: "Note",
                field: "note",
                filter: "agTextColumnFilter", 
                width: 230
            },
            {
                headerName: "State",
                field: "state",
                filter: "agTextColumnFilter", 
                width: 80,
            },
            {
                headerName: "Container",
                field: "container",
                filter: "agTextColumnFilter", 
                width: 100
            },
            {
                headerName: "Order number",
                field: "orderNumber",
                filter: "agTextColumnFilter", 
                width: 90
            },
            {
                headerName: "Sage state",
                field: "sageState",
                filter: "agTextColumnFilter", 
                width: 90
            },
            {
                headerName: "Created",
                field: "createDate",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm:ss"),
                width: 120
            },
            {
                headerName: "Created by",
                field: "createdBy",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "Changed",
                field: "changed",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YY HH:mm"),
                width: 80
            },
            {
                headerName: "Changed by",
                field: "changedBy",
                filter: "agTextColumnFilter", 
                width: 70
            },
            {
                headerName: "Date ETA",
                field: "dateEta",
                filter: "agTextColumnFilter", 
                cellRenderer: params => params.value && moment(params.value).format("DD.MM.YYYY"),
                width: 90
            }
    ],
    // autoGroupColumnDef: { minWidth: 250 },
    sideBar: 'columns',
    rowData: [],
    defaultColDef: {
        suppressMovable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: false,
        wrapText: true,
    },
    rowClassRules: {
        'rag-green-outer': function(params) { return params.data.state === "OK"; },
    },
    frameworkComponents: {
        logoRenderer: LogoRenderer,
      }
      
};





export default options;