import * as React from 'react';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import MobileUseChartBoxMovement from './MobileUseChartBoxMovement';
import { Button } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import { Modal, Collapse } from 'antd';


const { Panel } = Collapse;

export default function BoxContent({ data, chartData, visible }) {
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { infoPrintBox } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    const [positionLabel, setPositionLabel] = React.useState("");
    console.log("Box content info", data);

    React.useEffect(() => {
        if(data.position != null) setPositionLabel(data.position.position.label);
      }, [, data]);
    
    const printBox = async data => {
        const id = data.box.id;
        setLoading(true);
        try {
            const response = await callApi({ ...infoPrintBox, paramObject: { boxId: id } })
            setLoading(false);
        }
        catch (err) {
            printBoxError();
            setLoading(false);
        }
    }

    const printBoxError = () => {
        Modal.error({
            title: 'Error',
            content: "Connection problem, please try again later.",
        });
    }

 

    const { box: { ean, quantity, product: { code, description } = {} } = {} } = data;
    if (visible === true) {
        return (
            <div style={{marginTop: '100px'}}>
                <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center' }}>
                    <h3>{code}</h3>
                    <p>{description}</p>
                    <Button type="primary" onClick={() => printBox(data)} style={{ margin: 'auto' }}>Print box</Button>
                </div>
                <Collapse ghost>
                    <Panel header="Show graph" style={{justifyContent: 'center', margin: 'auto', textAlign: 'center'}}>
                        <MobileUseChartBoxMovement data={chartData} />
                        <div style={{ width: '80%', margin: 'auto', marginTop: '30px' }}>
                            <div style={{ display: 'flex', width: '70%', margin: 'auto', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Ean:</span>
                                <span>{ean}</span>
                            </div>
                            <div style={{ display: 'flex', width: '70%', margin: 'auto', marginTop: '15px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                <span>{quantity}</span>
                            </div>
                            <div style={{ display: 'flex', width: '70%', margin: 'auto', marginTop: '15px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                <span>{positionLabel}</span>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        )
    } else {
        return (
            <div />
        )
    }


}