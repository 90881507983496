import * as React from "react";
import BarcodeInput from "@bit/hlouzek.rhplus.barcode-input";
import usePage from "@bit/hlouzek.rhplus.page/dist/page";
import { Modal, Affix } from "antd";
import MobileBoxContent from "./components/MobileBoxContent";
import MobileStockMovements from "./components/MobileStockMovements";
import Enumerable from "linq";
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import { useLoaderContext } from "@bit/hlouzek.rhplus.loader/dist/reducer/Context";
import NavBar from "components/NavBar";
import LoadingOverlay from "components/LoadingOverlay";
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import useBeeper from '@bit/hlouzek.rhplus.beeper';

export default function MobileInfo() {
  const { setPageTitle } = usePage();
  const { callApi, errorCatch } = useApi(
    process.env.REACT_APP_COMPANYPOINT_PROXY
  );
  const { getInfo, productStockMovementsChart } = apis;
  const { loading, setLoading, key } = useLoaderContext();
  const [info, setInfo] = React.useState({});
  const [label, setLabel] = React.useState("");
  const [chartMovements, setChartMovements] = React.useState({});
  const [contentVisible, setContentVisible] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [top, setTop] = React.useState(90);
  const { success, error } = useBeeper();

  React.useEffect(() => {
    setPageTitle("Wms - Info");
  }, []);

  const onComplete = (e) => {
    console.log("success", e);
    loadData(e);
  };

  const onError = (e) => {
    console.log("error", e);
  };

  const loadData = async (ean) => {
    setIsActive(true);
    try {
      const response = await callApi({ ...getInfo, paramObject: { ean: ean } });
      const result = response.data;
      console.log("result", response);
      success();
      setInfo(result);
      setIsActive(false);
      setContentVisible(true);
      if (result.position != null) setLabel(result.position.label);
      if (result.box != null) {
        loadChartMovements(result.box.product.productId);
      }
    } catch (err) {
      error();
      loadingError(ean);
      setIsActive(false);
    }
  };

  const loadingError = (ean) => {
    Modal.error({
      title: "Error",
      content: `Cannot find ean ${ean}`,
    });
  };

  const loadChartMovements = async (productId) => {
    setLoading(true);
    try {
      const response = await callApi({
        ...productStockMovementsChart,
        paramObject: { productId: productId },
      });
      const result = response.data;
      setChartMovements(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const chartData = Enumerable.from(chartMovements.legends)
    .zip(chartMovements.values, (left, right) => ({
      legends: left,
      values: right,
    }))
    .toArray();

    UseScannerDetection({ onError, onComplete });

  return (
    <LoadingOverlay isActive={isActive}>
      <div className="mobile-info">
        <div style={{ backgroundColor: "white", marginTop: "15px" }}>
          <h3 style={{ paddingTop: "30px" }}>Scan barcode</h3>
          <BarcodeInput
            onSearch={onComplete}
            onError={onError}
            searchProperties={{
              style: {
                width: 300,
                marginBottom: "15px",
              },
            }}
            showSearchButton={true}
            showBarcodeButton={true}
            hideCameraAfterDetection={true}
            cameraPosition="down"
            cameraClassName="mt-3"
            className="barcode-input"
            barcodeIconStyle="barcode-icon"
          />
        </div>
        <div
          style={{
            width: "99%",
            textAlign: "left",
            margin: "auto",
            marginTop: "-60px",
            minHeight: "490px",
          }}
        >
          <MobileBoxContent
            visible={contentVisible}
            data={info}
            chartData={chartData}
          />
          <MobileStockMovements visible={contentVisible} data={info.box} />
        </div>
      </div>
      <div>
        <NavBar />
      </div>
    </LoadingOverlay>
  );
}
