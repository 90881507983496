import * as React from 'react';
import { UseScannerDetection } from '@bit/hlouzek.rhplus.barcodescanner';
import usePage from '@bit/hlouzek.rhplus.page/dist/page';
import LoadingOverlay from 'components/LoadingOverlay';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import { Modal, message } from 'antd';



export default function LaptopRelocate() {
    const { setPageTitle } = usePage();
    const [show, setShow] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { getBoxInfo, relocateBox } = apis;
    const { success, error } = useBeeper();
    const [boxInfo, setBoxInfo] = React.useState({});

    React.useEffect(() => {
        setPageTitle("Wms - Relocate")
    }, []);

    const onError = error => {
        console.log("ERROR! ", error);
    }

    const onComplete = code => {
        console.log("COMPLETE! ", code);
        setIsActive(true);
        loadData(code);
    }


    const loadData = async (code) => {
        console.log("I am working");
        const eanType = String(code).substr(0, 2);
        console.log("eanType", eanType);
        if (eanType === "27") {
            if(show === true) {
                error()
                setIsActive(false);
                Modal.error({
                    title: 'Error',
                    content: 'You can not relocate to box. Scan position.'
                })
            } else {
                try {
                    const response = await callApi({ ...getBoxInfo, paramObject: { Ean: code } })
                    console.log("relocate response", response.data);
                    success()
                    setBoxInfo(response.data);
                    setShow(true);
                    setIsActive(false);
    
                } catch (err) {
                    error()
                    errorMessage(err);
                    setIsActive(false);
                    setShow(false);
                }
            }
        } else if (eanType === "26") {
            if (code === boxInfo.position.ean) {
                Modal.error({
                    title: 'Error',
                    content: 'Destination position is same as source',
                });
                setShow(false);
            } else {
                try {
                    const relocateResponse = await callApi({ ...relocateBox, paramObject: { BoxId: boxInfo.id, PositionEan: code } })
                    if (relocateResponse.status === 200) {
                        Modal.success({ content: 'Box has been successfully relocate!' });
                        setShow(false);
                        setIsActive(false)
                        success()
                    } else {
                        Modal.error({ content: 'Error' });
                        error();
                        setIsActive(false);
                    }
                } catch (err) {
                    error()
                    errorMessage(err);
                    setShow(false);
                    setIsActive(false);
                }
            }
        }
    }

    const errorMessage = err => {
        Modal.error({ content: err });
    }




    UseScannerDetection({ onError, onComplete });


    return (
        <LoadingOverlay active={isActive} >
            <div style={{ minHeight: '600px' }}>
                {show ? (
                    <div style={{ width: '50%', justifyContent: 'center', margin: 'auto', marginTop: '30px' }}>
                        <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center' }}>
                            <h4>Scan destination position</h4>
                        </div>
                        <div style={{ marginTop: '30px', backgroundColor: '#f1f3f4', padding: '20px', boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.15)' }}>
                            <h3 style={{ marginTop: '30px' }}>{boxInfo.product.code}</h3>
                            <span>{boxInfo.product.description}</span>
                            <div style={{ width: '100%', margin: 'auto', marginTop: '30px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
                                    <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                        <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Quantity:</span>
                                        <span>{boxInfo.quantity}</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', marginRight: '30px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                        <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                        <span>{boxInfo.position.label}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ justifyContent: 'center', margin: 'auto', textAlign: 'center', marginTop: '30px' }}>
                        <h4>Scan source box</h4>
                        <img src={process.env.PUBLIC_URL + '/images/icons/barcode-icon.png'} style={{ width: '15%' }} />
                    </div>
                )}
            </div>
        </LoadingOverlay>
    )

}