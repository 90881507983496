import * as React from "react";
import { apis } from "api/apiService";
import useApi from "@bit/hlouzek.rhplus.api";
import useBeeper from '@bit/hlouzek.rhplus.beeper';
import Enumerable from "linq";
import QuantityChange from "./QuantityChange";
import Loader from '@bit/hlouzek.rhplus.loader';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';

export default function ProductInfoContent({
    productId,
    product,
    description,
    inventoryBox,
    disableInventoryBox,
    documentItemId
}) {
    const [data, setData] = React.useState([]);
    const [productDetailData, setProductDetailData] = React.useState({});
    const [placementInfo, setPlacementInfo] = React.useState({});
    const [quantityChangeVisible, setQuantityChangeVisible] = React.useState(false);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { productPlacementInfo, productDetail } = apis;
    const { loading, setLoading, key } = useLoaderContext();
    const { error } = useBeeper();

    React.useEffect(() => {
        setLoading(true);
        loadData(productId);
    }, [productId]);

    React.useEffect(() => {
        if (!!inventoryBox) {
            setQuantityChangeVisible(true);
        }
    }, [inventoryBox]);

    const loadData = async productId => {
        if (productId === 0) return;
        setLoading(true);
        try {
            const response = await callApi({ ...productPlacementInfo, paramObject: { productId: productId } })
            console.log("Modal response", response.data);
            setPlacementInfo(response.data);
            setData(response.data.boxes.filter((f) => f.quantity != 0 && !!f.position));
            await loadProductDetail(productId);
            setLoading();
        } catch (err) {
            setLoading(false);
            error();
        }
    };

    const loadProductDetail = async productId => {
        setLoading(true);
        try {
            const response = await callApi({ ...productDetail, paramObject: { productId: productId } })
            console.log("load product data response", response.data);
            setProductDetailData(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            error();
        }
    }

    const wms_sum = React.useCallback(
        () => Enumerable.from(data).sum((s) => s.quantity),
        [data]
    );
    const erp_sum = React.useCallback(
        () => productDetailData.inStock,
        [productDetailData]
    );
    const difference = () => wms_sum() - erp_sum();
    const differenceColor = () => {
        const diff = difference();
        if (diff === 0) return "";
        if (diff > 0) return "text-success";
        else return "text-danger";
    };

    const changeQuantity = (e) => {
        console.log("e", e.target.value);
    };

    const getQuantity = (box) => {
        if (!!data) {
            const item = data.filter((f) => f.ean === box);
            if (item && item.length === 1) {
                return item[0].quantity;
            }
        }
        return null;
    };

    const updateData = (inventoryBox, quantity) => {
        const newData = data.map((item) => {
            const { ean } = item;
            if (ean === inventoryBox) {
                return {
                    ...item,
                    quantity: parseInt(quantity),
                };
            }
            return item;
        });
        setData(newData);
    };

    const quantityChangeClose = ({ quantity }) => {
        loadData(productId);
        //updateData(inventoryBox, quantity);
        disableInventoryBox();
        setQuantityChangeVisible(false);
    };

    return (
        <React.Fragment>
            <div style={{ marginBottom: '20px' }}>{description}</div>
            <QuantityChange
                show={quantityChangeVisible}
                inventoryBox={inventoryBox}
                documentItemId={documentItemId}
                close={quantityChangeClose}
                enableInventory={data.length === 1}
                quantity={getQuantity(inventoryBox)}
            />
            <Loader>
                <table className="table table-fixed">
                    <thead>
                        <tr>
                            <th className="col-sm-3">Pos</th>
                            <th className="col-sm-6">Box</th>
                            <th className="col-sm-3">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => {
                            const { id, ean, quantity } = item;
                            const { position: { label } = {} } = item;
                            return (
                                <tr key={id}>
                                    <td className="col-sm-3">{label}</td>
                                    <td className="col-sm-6">{ean}</td>
                                    <td className="col-sm-3">{quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr className="font-weight-bold">
                            <td colSpan="2" className="col-sm-9">
                                sum WMS
                            </td>
                            <td className="col-sm-3">{wms_sum()}</td>
                        </tr>
                        <tr className="font-weight-bold">
                            <td className="col-sm-9" colSpan="2">
                                sum ERP
                            </td>
                            <td className="col-sm-3">{erp_sum()}</td>
                        </tr>
                        <tr className="font-weight-bold">
                            <td className="col-sm-9" colSpan="2">
                                difference
                            </td>
                            <td className="col-sm-3" className={differenceColor()}>
                                {difference()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Loader>
        </React.Fragment>
    );
}
