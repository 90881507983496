import { atom } from 'recoil';

export const stockInDocumentDetailState = atom({
  key: "stockInDocumentDetailState",
  default: "",
  dangerouslyAllowMutability: true
});

export const pickPositionState = atom({
  key: "pickPositionState",
  default: {
    key: '',
    boxId: '',
    stockOut: '',
    remainStockOut: '',
    position: '',
    state: '',
    positionName: '',
    documentItemId: '',
  },
  dangerouslyAllowMutability: true
})

export const pickBoxState = atom({
  key: "pickBoxState",
  default: {
    id: '',
    boxId: '',
    initQuantity: '',
    boxed: '',
    boxEanShort: ''
  },
  dangerouslyAllowMutability: true
})


  