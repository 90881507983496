import * as React from "react";
import { Modal } from 'antd';
import Loader from '@bit/hlouzek.rhplus.loader';

export default function InfoModal({ title, show, close, children }) {
    return (
        <Modal
            visible={show}
            title={title}
            footer={null}
            closable={true}
            maskClosable={true}
            onCancel={close}
            width={420}
        >
            {children}
        </Modal>
    );
}
