import React from 'react';
import { Modal, Button } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';
import LoadingOverlay from 'components/LoadingOverlay';
import useBeeper from '@bit/hlouzek.rhplus.beeper';


const { confirm } = Modal;

export default (props) => {
  const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
  const { removePalletFromDelivery } = apis;
  const { loading, setLoading, key } = useLoaderContext();
  const [isActive, setIsActive] = React.useState(false);
  const { success, error } = useBeeper();

  const id = props.data.id;


  const buttonClicked = id => {
    confirm({
      title: 'Remove pallet?',
      content: `Do you really want to remove pallet from delivery?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemovePalletFromDelivery(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  const handleRemovePalletFromDelivery = async id => {
    setIsActive(true);
    try {
      const response = await callApi({ ...removePalletFromDelivery, paramObject: { palletId: id } })
      console.log("remove response", response);
      if (response.status === 200) {
        success();
        deleteSuccess();
        setIsActive(false);


      } else {
        error();
        deleteError();
        setIsActive(false)
      }


    }
    catch (err) {
      setIsActive(false);
    }
  }

  const deleteSuccess = () => {
    Modal.success({
      content: 'Successfully deleted',
    });
  }


  const deleteError = () => {
    Modal.error({
      title: 'Delete failed',
      content: 'Connection problem, please try again later.',
    });
  }

  return (
    <LoadingOverlay
      active={isActive}
    >
      <span>
        <Button type="primary" className="ag-delete-button" onClick={() => buttonClicked(id)}>Delete</Button>
      </span>
    </LoadingOverlay>
  );
};




