import './shared/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import b2cauth from 'react-azure-adb2c';
import appsettings from './appsettings.json';
import {ApiContext} from '@bit/hlouzek.rhplus.api/dist/Context/ApiContext';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then(function () {
      console.log('Service worker registered!');
    })
    .catch(function(err) {
      console.log(err);
    });
}
const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  runWithAdal(authContext, () => {
      // Dynamically import our main App component, and render it
      const MainApp = require('./MainApp').default;
      ReactDOM.render(
        (
          <ApiContext.Provider value={{withCredentials: true, authContext}}>
            <MainApp/>
          </ApiContext.Provider>
        ), rootEl
      );
    })
};


if (module.hot) {
  runWithAdal(authContext, () => {
    module.hot.accept('./MainApp', () => {
      const MainApp = require('./MainApp').default;
      render(
        (
          <ApiContext.Provider value={{ withCredentials: true, authContext }}>
            <MainApp />
          </ApiContext.Provider>
        ),
        rootEl
      );
    });
  });
}

render();