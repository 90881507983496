import { atom } from 'recoil';
import {stockInDocumentsSortingEnum} from './constants';

export const stockInFilterAtom = atom({
  key: "stockInFilterAtom",
  default: {
    state: stockInDocumentsSortingEnum.BY_FINISHED,
    documentNumber: ''
  }
});

