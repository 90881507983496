import { atom } from 'recoil';


export const addPalletToDeliveryAtom = atom({
    key: "addPalletToDeliveryAtom",
    default: {
        pallets: []
    },
    dangerouslyAllowMutability: true

});