import * as React from 'react';
import CardBox from '@bit/hlouzek.rhplus.card-box';
import { Modal, Form, InputNumber, Input, Button } from 'antd';
import { apis } from 'api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';
import { useLoaderContext } from '@bit/hlouzek.rhplus.loader/dist/reducer/Context';


export default function MobileBoxDetail({ visibility, data }) {
    const [newQuantity, setNewQuantity] = React.useState();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const { callApi, errorCatch } = useApi(process.env.REACT_APP_COMPANYPOINT_PROXY);
    const { documentInventory } = apis;
    const { loading, setLoading, key } = useLoaderContext();

    const onFinish = (values) => {
        setNewQuantity(values.qty);
        setIsModalVisible(true);
    }

    const handleOk = () => {
        // handleCommit()
        setIsModalVisible(false);
      };
    
    const handleCancel = () => {
        setIsModalVisible(false);
      };
    
    // const handleCommit = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await callApi({ ...documentInventory, paramObject: { comit: ean } })
    //         const result = response.data;
    //         console.log("result", response);
    //     }
    //     catch (err) {
    //         setLoading(false);
    //     }
    // }

    if (visibility === true) {
        return (
            <div style={{ width: '90%', textAlign: 'left', margin: 'auto', marginTop: '20px' }}>
                <CardBox styleName="mb-3" heading="Box content">
                    <div style={{marginTop: '20px'}}>
                        <h3 style={{marginBottom: '0.2px'}}>{data.product.code}</h3>
                        <p>{data.product.description}</p>
                    </div>
                    <div>
                        <div style={{ marginLeft: '20px' }}>
                            <div style={{ display: 'flex', width: '70%', marginBottom: '10px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '60px' }}>Id:</span>
                                <span>{data.id}</span>
                            </div>
                            <div style={{ display: 'flex', width: '70%', marginBottom: '10px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '47px' }}>Ean:</span>
                                <span>{data.ean}</span>
                            </div>
                            <div style={{ display: 'flex', width: '70%', marginBottom: '10px', textAlign: 'center', borderBottom: '0.1px solid #c2c1c1' }}>
                                <span style={{ color: '#c2c1c1', marginRight: '20px' }}>Position:</span>
                                <span>{data.position.ean}</span>
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '20px', marginLeft: '20px' }}>
                            <Form
                                name="basic"
                                initialValues={{
                                    remember: true,
                                    qty: data.quantity
                                }}
                                onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="Qty"
                                    name="qty"
                                >
                                    <InputNumber className="qty-input" />
                                </Form.Item>
                                <Form.Item
                                    label="Note"
                                    name="note"
                                >
                                    <Input className="note-input" allowClear style={{ width: '90%' }} />
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    <Button type="primary" htmlType="submit">Save changes</Button>
                                </Form.Item>
                            </Form>
                            <Modal title="Commit quantity change" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Commit">
                                <div style={{width: '60%'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}><p>Original quantity:</p><p style={{fontWeight: 'bold'}}>{data.quantity}</p></div>
                                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom:'0.1px solid #c2c1c1' }}><p>New quantity:</p><p style={{fontWeight: 'bold'}}>{newQuantity}</p></div>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}><p>Difference:</p><p style={{fontWeight: 'bold'}}>{newQuantity - data.quantity}</p></div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </CardBox>
            </div>
        )
    } else {
        return (<div />)
    }
}