import * as React from "react";
import StockOut from './StockOut';
import {RecoilRoot} from 'recoil';
import {favicons, useFavicon} from '@bit/hlouzek.rhplus.favicon';

function getTitleEl() {
  return document.getElementById("app-title");
}

export default function Root() {
  useFavicon({url: favicons.wms.stockout});

  const titleChange = () => {
    const title = getTitleEl();
    console.log("title", title);
    // favicon.href = `${process.env.PUBLIC_URL} + /favicons/stockInIcon.ico`;
    title.innerHTML = "Stock out"
  };

  React.useEffect(() => {
    titleChange();
}, []);

  return (
    <RecoilRoot>
      <StockOut />
    </RecoilRoot>
  )
}