import * as React from 'react';
import LaptopDetail from "./LaptopVersion";
import MobileDetail from "./MobileVersion";
import { ShowAt, HideAt } from "react-with-breakpoints";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useRecoilValue, useRecoilState } from "recoil";
import { stockOutDocumentDetailState } from './recoil/atoms';


export default function StockOutDetail({match}) {
    const [documentNumberAtom, setDocumentNumberAtom] = useRecoilState(stockOutDocumentDetailState);


    React.useEffect(() => {
        const {documentNumber} = match.params;
        setDocumentNumberAtom(documentNumber);
    }, [match]);

    return (
        <div>
            <React.Suspense
                fallback={<Loader
                    type="Oval"
                    color="#46c78f"
                    height={60}
                    width={60}
                    timeout={3000} //3 secs
                    style={{ textAlign: 'center', margin: 'auto', marginTop: '70px' }}
                />}>
                <div>
                    <ShowAt breakpoint="xlarge">
                        <LaptopDetail />
                    </ShowAt>
                    <ShowAt breakpoint="large">
                        <LaptopDetail />
                    </ShowAt>
                    <ShowAt breakpoint="medium">
                        <MobileDetail />
                    </ShowAt>
                    <ShowAt breakpoint="small">
                        <MobileDetail />
                    </ShowAt>
                </div>
            </React.Suspense>

        </div>
    )
}




